import React, {Component, useState} from "react";
import '../index.css';
import { Redirect ,withRouter } from "react-router-dom";
import AbonnementCollapsible from "../Components/KlantPagina/AbonnementCollapsible";
import HttpService from "../services/HttpService";
import { APIUrl } from "../Globals";

class KlantPagina extends Component{

    state={
        loading: false,
        klant:null,
        error:false,
        keycloak: null, 
        authenticated: false
    }

    componentDidMount() {
        let url = APIUrl + `/Klanten/`+this.props.match.params.id.toString();
        const httpService = HttpService;
        httpService.configure();
        const axios = httpService.getAxiosClient();

        this.setState({
            loading: true,
            klant: null,
            error: false
        })
        axios.get(url).then( response =>{
            this.setState({
                loading: false,
                klant: response.data,
                error: false
            })
        }).catch(()=>{
            this.setState({
                loading: false,
                klant: null,
                error: true
            })
        })
    }

    render(){
                if(this.state.klant!=null){
                    let klant = this.state.klant;
                    let abonnementen;
                    if(klant.abonnementen){
                        abonnementen = klant.abonnementen;
                    }
                    return(
                        <div class="h-full border-gray-400 border-2 mt-2">
                            <h3 style={{backgroundColor: '#00aaad'}} className="col-span-12 p-3 text-center font-semibold mb-5 border-gray-400 border-b-2">Klant</h3>
                            <div class="m-3">
                                <div class="grid grid-cols-8 bg-gray-50 h-1/4">
                                    <div class="col-span-1">
                                        <button class="h-2/3 w-2/3 m-3 border-2 bg-gray-200 border-gray-600 rounded-lg" name="btnAbonnement"
                                            onClick={()=>{this.props.history.push({pathname:"/Abonnementtoevoegen",state:{klantObject:klant}})}}>Abonnement toevoegen</button>
                                    </div>
                                    <div class="col-span-1">
                                        <button class="h-2/3 w-2/3 m-3 bg-gray-200 border-2 border-gray-600 rounded-lg" name="btnInterface"
                                            onClick={()=>{this.props.history.push({pathname:"/Interfacetoevoegen",state:{klantObject:klant}})}}>Interface toevoegen</button>
                                    </div>
                                </div>
                                <div class="bg-gray-50 h-2/3">
                                    <div class="grid grid-cols-9">
                                        <div class="m-2 col-span-1">DebiteurNr: </div>
                                        <input class="bg-gray-200 m-2 col-span-2 border border-black" type="text" readOnly={true} name="txtDebiteurNr" value={klant.debiNr}/>
                                    </div>
                                    <div class="grid grid-cols-9">
                                        <div class="m-2 col-span-1">RelatieNr: </div>
                                        <input class="bg-gray-200 m-2 col-span-2 border border-black" type="text" readOnly={true} name="txtRelatieNr" value={klant.relaNr}/>
                                    </div>
                                    <div class="grid grid-cols-9">
                                        <div class="m-2 col-span-1">Bedrijfsnaam: </div>
                                        <input class="bg-gray-200 m-2 col-span-2 border border-black" type="text" readOnly={true} name="txtBedrijfsnaam" value={klant.naam}/>
                                    </div>
                                </div>
                            </div>
                            <div class="m-2">
                                <table style={{textAlign:"left"}} name="aboTable" class="table-fixed bg-blue-100 border-2 border-gray-600 w-full">
                                    <thead>
                                        <tr>
                                            <th class="w-3/12">Abonnement naam</th>
                                            <th class="w-1/12">Startdatum</th>
                                            <th class="w-1/12">Einddatum</th>
                                            <th class="w-1/12">Status</th>
                                            <th class="w-1/12">Interfaces</th>
                                            <th class="w-1/12"></th>
                                            <th class="w-1/12"></th>
                                            <th class="w-3/12"></th>
                                            {/* <th class="w-1/12"></th> */}
                                            
                                        </tr>
                                    </thead>
                                    
                                    {abonnementen && abonnementen.map((abonnement,index) =>
                                        <AbonnementCollapsible abonnement = {abonnement} klant={klant} index={index}/>
                                    )}
                                    
                                </table>
                            </div>
                        </div>
                    )
                }
                else if(this.state.error){
                    return <div>Er is iets mis gegaan bij het ophalen van de klant.</div>
                }
                else{
                    return <div>Loading...</div>
                }
            }
}

export default withRouter(KlantPagina);