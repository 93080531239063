import React, { Component, useState } from "react";
import HttpService from "../services/HttpService";
import "../index.css";
import UserService from "../services/UserService";
import { APIUrl } from "../Globals";
import Select from "react-select";
import ProductieOrderCollapsible from "../Components/PlanningsPagina/ProductieOrderCollapsible";
import HalfFabricatenCollapsible from "../Components/PlanningsPagina/HalfFabricatenCollapsible";

class PlanningPagina extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      klanten: null,
      error: false,
      selectedCustomerIndex: 0,
      axios: HttpService.getAxiosClient(),
      productieorders: null,
      bewerkingenplanning: null,
      halffabricaten: null,
      bewerkingen: [],
      displayBewerkingen: [],
      Showinactive: false,
    };
  }

  componentDidMount() {
    let debiNr = UserService.getDebiNr();
    if (debiNr != null) {
      let url =
        APIUrl +
        `/klanten?limited=true&` +
        debiNr.map(function (number) {
          return "debiNr=" + number + "&";
        });
      url = url.slice(0, -1);
      url = url.replaceAll(",", "");
      this.setState({
        loading: true,
        klanten: null,
        error: false,
      });
      this.state.axios
        .get(url)
        .then((response) => {
          this.setState({
            loading: false,
            klanten: response.data.sort(),
            error: false,
          });

          if (this.state.klanten.length > 0) {
            this.changeCustomer(0);
            this.getProductieorders();
          }
          this.forceUpdate();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            klanten: null,
            error: true,
            errorMessage: error.message,
          });
        });
    }
  }

  getBewerkingen(prdhNum, prdrNum) {
    let url =
      APIUrl + `/Planning/bewerkingen?prdh_num=${prdhNum}&prdr_num=${prdrNum}`;

    console.log(prdhNum);
    console.log(prdrNum);
    console.log(this.state);
    this.setState({ loading: true });

    this.state.axios
      .get(url)
      .then((response) => {
        let bewerkingen = response.data.sort(
          (a, b) =>
            new Date(b.plnb_dat_eind).getTime() -
            new Date(a.plnb_dat_eind).getTime()
        );
        this.setState({
          loading: false,
          bewerkingen: bewerkingen,
          displayBewerkingen: bewerkingen.filter((b) => b.plnb_gereed == false),
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: true,
          errorMessage: error.message,
        });
      });
  }

  getProductieorders() {
    let klant = this.state.klanten[this.state.selectedCustomerIndex];
    console.log(klant);
    if (klant) {
      let url = APIUrl + `/Planning/productieorders?t_klant=${klant}`;

      this.setState({ loading: true });

      this.state.axios
        .get(url)
        .then((response) => {
          response.data.forEach((order) => {
            switch (order.sys_gebr_wijzig) {
              case "smu":
                order.sys_gebr_wijzig = "Stijn Murrer";
                break;
              case "eba":
                order.sys_gebr_wijzig = "Eric Bakker";
                break;
              case "evb":
                order.sys_gebr_wijzig = "Ed van den Brule";
                break;
              case "jve":
                order.sys_gebr_wijzig = "Justin van Eijck";
                break;
              case "mvo":
                order.sys_gebr_wijzig = "Mart van Oosterhout";
                break;
              case "nsc":
                order.sys_gebr_wijzig = "Niek Scheepens";
                break;
              case "ske":
                order.sys_gebr_wijzig = "Sjoerd Kempe";
                break;
              default:
                break;
            }
          });
          this.setState({
            loading: false,
            productieorders: response.data,
          });

          if (response.data.length > 0) {
            console.log(response.data);
            const lastOrder = response.data[response.data.length - 1];
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: true,
            errorMessage: error.essage,
          });
        });
    }
  }

  changeCustomer(index) {
    this.setState(
      {
        selectedCustomerIndex: index,
        productieorders: null,
        bewerkingen: null,
        displayBewerkingen: null,
      },
      () => this.getProductieorders()
    );
  }

  changeActive() {
    var newState = !this.state.Showinactive;
    this.setState(
      {
        Showinactive: newState,
      },
      () => this.setDisplayBewerkingen()
    );
  }

  setDisplayBewerkingen() {
    if (this.state.Showinactive) {
      this.setState({
        displayBewerkingen: this.state.bewerkingen,
      });
    } else {
      this.setState({
        displayBewerkingen: this.state.bewerkingen.filter(
          (b) => b.plnb_gereed == false
        ),
      });
    }
  }

  render() {
    let klanten = this.state.klanten;
    if (klanten != null) {
      let customerOptions = klanten.map((klant, i) => ({
        label: klant,
        value: i,
      }));
      let halffabricaten = this.state.halffabricaten;

      return (
        <div class="w-full grid grid-cols-7">
          <div class="col-span-1">
            <div class="m-2 font-bold"></div>
            Gebruiker: {UserService.getUsername()} <br />
          </div>
          <Select
            class="ml-1 col-span-2 m-3"
            onChange={(evt) => {
              this.changeCustomer(evt.value);
            }}
            value={customerOptions[this.state.selectedCustomerIndex]}
            options={customerOptions}
          />

          <div class="m-2">
            Volledige project historie:
            <input
              class="m-2"
              type="checkbox"
              checked={this.state.Showinactive}
              onChange={() => this.changeActive()}
            />
          </div>

          <div class="col-span-8 m-2 rounded-lg">
            <div class="mb-3 font-bold">Projecten</div>
            <table
              style={{ textAlign: "left" }}
              name="aboTable"
              class="table-fixed bg-blue-100 border-2 border-black w-full rounded-table"
            >
              <thead>
                <tr>
                  <th class="bg-blue-100 w-4/12">Start datum project</th>{" "}
                  {/* t_geplande_startdatum */}
                  <th class="bg-blue-100 w-6/12">Huidig project</th>{" "}
                  {/*/ prdh_oms_1 */}
                  <th class="bg-blue-100 w-3/12">
                    Verantwoordelijk Supply Drive medewerker
                  </th>
                </tr>
              </thead>

              {this.state.productieorders &&
                this.state.productieorders.map((order, index) => (
                  <ProductieOrderCollapsible
                    productieorder={order}
                    getBewerkingen={this.getBewerkingen.bind(this)}
                  />
                ))}

              {/* <tbody>
                {this.state.bewerkingen &&
                  this.state.bewerkingen.map((bewerkingen, index) => (
                    <tr key={`prod-${index}`}>
                      <td>{bewerkingen.prdh_num}</td>
                      <td>{bewerkingen.plnb_dat_start}</td>
                      <td>{bewerkingen.prdr_num}</td>
                      <td>..</td>

                      {this.state.productieorders &&
                        this.state.productieorders.map(
                          (productieorder, index) => (
                            <tr key={`prod-${index}`}></tr>
                            //<ProductieorderCollapsible productieorder={productieorder}/>
                          )
                        )}
                    </tr>
                  ))}
              </tbody> */}
            </table>
          </div>
          <div class="col-span-4 m-1 rounded-lg">
            <div class="mb-3 font-bold">Overzicht project gebeurtenis</div>
            <table
              name="aboTable"
              class="table-fixed bg-blue-200 border-2 border-black w-full rounded-table"
              style={{ textAlign: "left" }}
            >
              <thead>
                <tr class="bg-blue-200">
                  <th class="bg-blue-100">Huidige</th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th className="bg-blue-200 border-black">
                    Geplande einddatum actie
                  </th>
                  <th class="bg-blue-200 border-black rounded-table">
                    Bewerking
                  </th>
                  <th class="bg-blue-200 border-black rounded-table">
                    Status actie
                  </th>
                  <th class="bg-blue-200 border-black rounded-table">
                    Beschrijving actie
                  </th>
                  <th class="bg-blue-200 border-black rounded-table">
                    Actie verantwoordelijk
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.displayBewerkingen &&
                  this.state.displayBewerkingen.map((bewerking, index) => (
                    <tr class="bg-blue-200 border-black rounded-table">
                      <td class="bg-blue-200 border-black rounded-table">
                        {bewerking.plnb_dat_eind}
                      </td>
                      <td class="bg-blue-200 border-black rounded-table">
                        {bewerking.bwrk_num_bwrk_oms}
                      </td>
                      <td class="bg-blue-200 border-black rounded-table">
                        {bewerking.plnb_gereed
                          ? "Gereed"
                          : bewerking.plnb_gestart
                          ? "Gestart"
                          : "Niet gestart"}
                      </td>
                      <td class="bg-blue-200 border-black rounded-table">
                        {bewerking.plnb_memo}
                      </td>
                      <td class="bg-blue-200 border-black rounded-table">
                        {bewerking.rsrc_num_rsrc_oms}
                      </td>
                    </tr>
                  ))}
              </tbody>

              <thead>
                <tr class="bg-blue-200">
                  <th class="bg-blue-100">Voorgaande afgeronde acties</th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                  <th class="bg-blue-100"></th>
                </tr>
                <th class="bg-blue-200 w-4/15">Datum afgerond</th>{" "}
                {/* t_geplande_startdatum */}
                <th class="bg-blue-200 w-5/15">Bewerking</th>{" "}
                {/*/ prdh_oms_1 */}
                <th class="bg-blue-200 w-5/15">Status</th> {/* leeg */}
                <th class="bg-blue-200 w-4/15">Beschrijving actie</th>{" "}
                {/* leeg */}
                <th class="bg-blue-200 w-4/15">Actie verantwoordelijk</th>
              </thead>
              <tbody>
                {this.state.bewerkingen &&
                  this.state.bewerkingen.map((bewerking, index) => (
                    <tr class="bg-blue-200">
                      <td class="bg-blue-200">{bewerking.plnb_dat_eind}</td>
                      <td class="bg-blue-200">DATA</td>
                      <td class="bg-blue-200">DATA</td>
                      <td class="bg-blue-200">DATA</td>
                      <td class="bg-blue-200">DATA</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div class="col-span-3 ml-3 mt-3 rounded-lg">
            <div class="mb-3 font-bold">Klant informatie</div>

            <table
              name="aboTable"
              class="table-fixed bg-blue-100 border-2 border-black w-full"
              style={{ textAlign: "left" }}
            >
              <thead>
                <tr>
                  <th class="bg-blue-200 w-1/12">Klant naam</th>
                </tr>
              </thead>
              <thead>
                <thead>
                  <td>Test B.V.</td>
                </thead>

                <thead>
                  <tr>
                    <th class="bg-blue-200 w-1/12">Abonnement naam</th>
                  </tr>
                </thead>
                <thead>
                  <td>Private connector type B</td>
                </thead>
                <tr>
                  <th class="bg-blue-200 w-1/12">Algemeen</th>
                </tr>
              </thead>
              <thead>
                <td>- Inkoop bevestiging dagelijks geupdate</td>
              </thead>
              <thead>
                <td>- Vanuit mailbox automatisch aangeroepen wordt</td>
              </thead>
            </table>
          </div>
        </div>
      );
    } else if (this.state.error) {
      return <div>{this.state.errorMessage}</div>;
    } else {
      return <div>Loading...</div>;
    }
  }
}
export default PlanningPagina;
