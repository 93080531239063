import { useState } from "react";

function Collapsible(props) {
  const [isOpen, setIsOpen] = useState(false);
  let item = props.item;
  let klanten = props.item.klanten;

  const containerStyle = {
    border: "1px solid black",
    paddingLeft: "10px",
  };

  return (
    <tbody className="border-2 border-gray-600">
      <tr
        className="border-1 border-gray-600"
        onClick={() => setIsOpen(!isOpen)}
      >
        <td>{item.naam}</td>
        <td>{item.totaal}</td>
      </tr>
      {isOpen && klanten.length > 0 && (
        <tr class="padding-3 border-2 border-black">
          <td class="font-semibold">klantnaam</td>
          <td class="font-semibold">debinr</td>
          <td class="font-semibold">aantal</td>
        </tr>
      )}
      {isOpen &&
        klanten.length > 0 &&
        klanten.map((klant) => (
          <tr>
            <td style={containerStyle}>{klant.klantNaam}</td>
            <td style={containerStyle}>{klant.klantDebiNr}</td>
            <td style={containerStyle}>{klant.count}</td>
          </tr>
        ))}
      {isOpen && klanten.length < 1 && <div>-</div>}
    </tbody>
  );
}
export default Collapsible;
