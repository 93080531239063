import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
} from "recharts";
import Months from "../../Enum/Months";

function BerichtenGraph(props) {
  return (
    <div class="col-span-3">
      <div class="font-bold mb-2">Aantal Berichten</div>
      <div class="">
        <LineChart
          width={800}
          height={400}
          data={
            props.graphOptions.selectedYear
              ? props.graphOptions.selectedMonth
                ? props.graphOptions.data.filter((obj) => {
                    return (
                      obj.jaar === props.graphOptions.selectedYear &&
                      obj.maand === props.graphOptions.selectedMonth
                    );
                  })
                : props.graphOptions.data.filter((obj) => {
                    return obj.jaar === props.graphOptions.selectedYear;
                  })
              : props.graphOptions.data
          }
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis
            tickLine={true}
            xAxisId={0}
            dy={0}
            dx={-0}
            label={{ value: "", angle: 0, position: "bottom" }}
            interval={0}
            dataKey="maand"
            tick={{ fontSize: 9, angle: 0 }}
          />
          <XAxis
            xAxisId={1}
            dy={-15}
            dx={0}
            label={{ value: "", angle: 0, position: "bottom" }}
            interval={10}
            dataKey="jaar"
            tickLine={false}
            tick={{ fontSize: 9, angle: 0 }}
          />
          <YAxis dataKey="aantal" name="Aantal berichten" />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" dataKey="aantal" stroke="#ff7300" yAxisId={0} />
        </LineChart>
      </div>
      <div>
        {props.selectMessage ? props.selectMessage : null}
        {props.selectMessage ? (
          <button
            class="bg-blue-200 border-black border m-5"
            onClick={() => props.reset()}
          >
            Reset
          </button>
        ) : null}
        {props.selectedInterface ? (
          <button
            class="bg-blue-200 border-black border m-5"
            onClick={() => props.ShowBerichten()}
          >
            Berichten
          </button>
        ) : null}
      </div>
    </div>
  );
}
export default BerichtenGraph;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        <p className="intro">
          {Months[label - 1] + " " + payload[0].payload.jaar}
        </p>
        <p className="label">{`Aantal berichten: ${payload[0].payload.aantal}`}</p>
      </div>
    );
  }

  return null;
};
