import React, { Component } from "react";
import UserService from "../services/UserService";

class PrivatePage extends Component{
    render(){
        return(<div>
            Private page.
             username: {UserService.getUsername()}
        </div>);
    }
}
export default PrivatePage;