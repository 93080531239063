import React, { useState } from "react";

function AbonnementCollapsibleKlantDashboard(props) {
  const [isOpen, setIsOpen] = useState(false);
  let abonnement = props.abonnement;
  let interfaces = abonnement.interfaces;
  let changeData = props.changeData;
  let resetData = props.resetData;
  let changeDataAbo = props.changeDataAbo;
  let selectedMonth = props.month;
  let selectedYear = props.year;
  {
    console.log(abonnement);
  }
  {
    console.log("test");
  }

  function getTotalBerichten(maandaantallen) {
    if (selectedYear != null && selectedYear > 0) {
      maandaantallen = maandaantallen.filter((ma) => ma.jaar == selectedYear);
    }

    let total = 0;
    for (let i = 0; i < maandaantallen.length; i++) {
      total += maandaantallen[i].aantal;
    }

    return total;
  }

  function GetTotalBerichten() {
    let total = 0;
    for (let i = 0; i < interfaces.length; i++) {
      for (let ma = 0; ma < interfaces[i].berichtenPerMaand.length; ma++) {
        if (selectedMonth > 0) {
          if (
            selectedMonth == interfaces[i].berichtenPerMaand[ma].maand &&
            interfaces[i].berichtenPerMaand[ma].jaar == selectedYear
          ) {
            total += interfaces[i].berichtenPerMaand[ma].aantal;
          }
        } else if (selectedYear > 0) {
          if (interfaces[i].berichtenPerMaand[ma].jaar == selectedYear) {
            total += interfaces[i].berichtenPerMaand[ma].aantal;
          }
        } else {
          total += interfaces[i].berichtenPerMaand[ma].aantal;
        }
      }
    }
    return total;
  }

  function AboOnclick() {
    if (!isOpen) {
      changeDataAbo(abonnement);
    } else {
      resetData();
    }
    setIsOpen(!isOpen);
  }

  return (
    <tbody className="border-2 border-black justify-items-center">
      <tr className="border-1 border-black" onClick={() => AboOnclick()}>
        <td>{abonnement.naam}</td>
        {abonnement.startdatum ? (
          <td>{abonnement.startdatum.toString().split("T")[0]}</td>
        ) : (
          <td />
        )}
        {abonnement.einddatum ? (
          <td>{abonnement.einddatum.toString().split("T")[0]}</td>
        ) : (
          <td />
        )}
        <td>{abonnement.status}</td>
        <td>{interfaces.length}</td>
        <td>{GetTotalBerichten()}</td>
      </tr>
      {isOpen && interfaces.length > 0 && (
        <tr class="bg-blue-200">
          <td class="font-semibold ">Naam</td>
          <td class="font-semibold ">Verzender</td>
          <td class="font-semibold ">Ontvanger</td>
          <td class="font-semibold ">Status</td>
          <td class="font-semibold ">Startdatum</td>
          <td class="font-semibold ">Einddatum</td>
          <td class="font-semibold ">Aantal berichten</td>
        </tr>
      )}
      {isOpen &&
        interfaces.length > 0 &&
        interfaces.map((interfaceObj) => (
          <tr
            className="bg-blue-200 border-black border"
            onClick={() => changeData(interfaceObj)}
          >
            <td>{interfaceObj.omschrijving}</td>
            {/*interfaceObj.verzenderNaam == "Stichting SCSN" && interfaceObj.scsnNaamVerzender!=null? (
              <td>{interfaceObj.scsnNaamVerzender}</td>
            ) : (
              <td>{interfaceObj.verzenderNaam}</td>
            )*/}
            <td>{interfaceObj.verzenderNaam}</td>
            {/*interfaceObj.ontvangerNaam == "Stichting SCSN" && interfaceObj.scsnNaamOntvanger!=null? (
              <td>{interfaceObj.scsnNaamOntvanger}</td>
            ) : (
              <td>{interfaceObj.ontvangerNaam}</td>
            )*/}
            <td>{interfaceObj.ontvangerNaam}</td>
            <td>{interfaceObj.status}</td>
            {interfaceObj.startdatum ? (
              <td>{interfaceObj.startdatum.toString().split("T")[0]}</td>
            ) : (
              <td />
            )}
            {interfaceObj.einddatum ? (
              <td>{interfaceObj.einddatum.toString().split("T")[0]}</td>
            ) : (
              <td />
            )}
            <td>
              {selectedMonth
                ? interfaceObj.berichtenPerMaand.find(
                    (ma) => ma.jaar == selectedYear && ma.maand == selectedMonth
                  ) != null
                  ? interfaceObj.berichtenPerMaand.find(
                      (ma) =>
                        ma.jaar == selectedYear && ma.maand == selectedMonth
                    ).aantal
                  : 0
                : getTotalBerichten(interfaceObj.berichtenPerMaand)}
            </td>
          </tr>
        ))}
      {isOpen && interfaces.length < 1 && <div>-</div>}
    </tbody>
  );
}
export default AbonnementCollapsibleKlantDashboard;
