import React, { useState } from "react";
import styles from "../../App.css";

export const RetryTooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <div
        className="messagecontainer"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
        {isVisible && <div className="messagetext">{text}</div>}
      </div>
    </div>
  );
};
