import { useState, useEffect } from "react";
import { APIUrl } from "../../Globals";
import HttpService from "../../services/HttpService";
import HalfFabricatenCollapsible from "./HalfFabricatenCollapsible";

function ProductieOrderCollapsible(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [halffabricaten, setHalffabricaten] = useState(null);
  let productieorder = props.productieorder;
  let axios = HttpService.getAxiosClient();

  useEffect(() => {
    getHalffabricaten(productieorder.prdh_num);
  }, []);

  function getHalffabricaten(prdhNum) {
    let url = APIUrl + `/Planning/halffabricaten?prdh_num=${prdhNum}`;
    axios
      .get(url)
      .then((response) => {
        setHalffabricaten(response.data);
        console.log(response.data);
      })
      .catch((error) => {});
  }

  return (
    <tbody className="border-2 border-gray-600 justify-items-center w-full">
      <tr
        className="border-1 border-gray-600"
        key={productieorder.prdh_num}
        onClick={() => setIsOpen(!isOpen)}
      >
        <td>{productieorder.t_geplande_startdatum}</td>
        <td>{productieorder.prdh_oms_1}</td>
        <td>{productieorder.sys_gebr_wijzig}</td>
      </tr>

      {isOpen && halffabricaten.length > 0 && (
        <tr className="bg-blue-200 border-black border">
          <td class="font-semibold ">Startdatum project stap:</td>
          <td class="font-semibold ">Omschrijving:</td>
          <td class="font-semibold ">Verwachte project einddatum:</td>
          <th class="font-semibold "></th>
         
        </tr>
      )}
      {isOpen &&
        halffabricaten != null &&
        halffabricaten.length > 0 &&
        halffabricaten.map(
          (halffabricaat) => (
            <tr
              className="bg-blue-200 border-black border"
              onClick={() =>
                props.getBewerkingen(
                  halffabricaat.prdh_num,
                  halffabricaat.prdr_num
                )
              }
            >
              <td>{halffabricaat.prdr_dat_start}</td>
              <td>{halffabricaat.prdr_oms_1}</td>
              <td>{halffabricaat.prdr_dat_gepland}</td>
              <td>{halffabricaat.prdr_aantal}</td>
              
            </tr>
          )
          //    <HalfFabricatenCollapsible halffabricaat={halffabricaat}/>
        )}
      {isOpen && halffabricaten.length < 1 && (
        <div>Geen bewerking gevonden</div>
      )}
    </tbody>
  );
}
export default ProductieOrderCollapsible;
