import { Component } from "react";
import UserService from "../services/UserService";

class LoginPage extends Component{
    render(){
        return(
            <div>
                <button onClick={()=>UserService.doLogin()}> Login</button>
            </div>
        )
    }
}
export default LoginPage