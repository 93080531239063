import React, {Component, useState} from "react";
import HttpService from "../services/HttpService";
import '../index.css';
import { Redirect } from "react-router";
import RenderOnRole from "../Utilities/RenderOnRole"
import { withRouter } from "react-router-dom";
import { APIUrl } from "../Globals";

class AbonnementToevoegenPagina extends Component{
    state={
        loading: false,
        abonnementTypes:null,
        abonnementType:null,
        klant:null,
        error:false,
        abonnementId:null,
        abonnement:null,
        aantal:1,
        startdatum:null,
        einddatum:null,
        status:null,
        responseMessage:"",
        axios:null,
        redirect:null,
        update:false
    }

    componentDidMount() {
        try{
            if(this.props.location.state.selectedAbonnement){
                this.state.abonnement = this.props.location.state.selectedAbonnement;
                this.state.update = true;
            }
        }
        catch(ex){
            this.props.history.push("/");
        }

        let url = APIUrl + `/Abonnementen?types=true`;
        const httpService = HttpService;
        httpService.configure();
        this.state.axios = httpService.getAxiosClient();

        this.state.klant = this.props.location.state.klantObject;
        this.setState({
            loading: true,
            abonnementTypes: null,
            error: false
        })
        this.state.axios.get(url).then( response =>{
            this.setState({
                loading: false,
                abonnementTypes: response.data,
                abonnementType:response.data[0],
                error: false
            })
            if(this.state.update&&this.state.abonnement&&this.state.loading==false){
                this.SetValues();
            }
        }).catch(()=>{
            this.setState({
                loading: false,
                abonnementTypes: null,
                error: true
            })
        })
    }

    SetValues(){
        let abonnement = this.state.abonnement;
        this.setState({
            abonnementId:abonnement.abonnementId,
            abonnementType:abonnement.abonnementType,
            aantal:abonnement.aantal,
            startdatum:abonnement.startdatum,
            einddatum:abonnement.einddatum,
            status:abonnement.status
        })
    }

    PostAbonnement(){
        if(this.state.abonnementType==null){
            this.setState({responseMessage: "Geen abonnementType geselecteerd."})
            return;
        }
        if(this.state.aantal<1){
            this.setState({responseMessage: "Geen geldige waarde voor aantal ingevuld"})
            return;
        }

        
            let status = "Inactief";
            let startdatum = this.state.startdatum;
            let einddatum = this.state.einddatum;
            let today = Date.now();
            if(startdatum!=null&&new Date(startdatum).getTime()<=today){
                status = "Actief";
            }
            // if(!startdatum&&einddatum!=null){
            //     this.setState({responseMessage: "Einddatum kan niet aangegeven zijn zonder begindatum."})
            //     return;
            // }
            if(einddatum!=null&&new Date(einddatum).getTime()<=new Date(startdatum).getTime()){
                this.setState({responseMessage: "De einddatum kan niet eerder zijn dan de startdatum."})
                return;
            }
            this.state.status = status;
            if(!this.state.update){
                this.state.abonnementId = 0;
            }
        
        

        let abonnement = {
            "abonnementId":this.state.abonnementId,
            "abonnementType":this.state.abonnementType,
            "aantal":this.state.aantal,
            "startdatum":this.state.startdatum,
            "einddatum":this.state.einddatum,
            "status":this.state.status
        }

        if(this.state.update){
            let url = APIUrl + `/Abonnementen/`+this.state.abonnementId;
            this.state.axios.put(url,abonnement).then(response=>{
                if(response.status==200){
                    this.state.responseMessage = "Abonnement succesvol geupdate.";
                    this.setState({redirect:true});
                }
            }).catch(error=>{
                this.state.responseMessage = error.toString();
            })
        }
        else{
            let url = APIUrl + `/Abonnementen/`+this.state.klant.klantId;
            this.state.axios.post(url,abonnement).then(response=>{
                if(response.status==200){
                    this.state.responseMessage = "Abonnement succesvol toegevoegd.";
                    this.setState({redirect:true});
                }
            }).catch(error=>{
                this.state.responseMessage = error.toString();
            })
        }
    
    }

    SelectAbonnementOption(){
        let option = this.state.abonnementTypes.findIndex(abonnement=>abonnement.aboTypeId == this.state.abonnementType.aboTypeId);
        return option;
    }

    SetAbonnementValue(evt){
        this.setState({abonnementType: this.state.abonnementTypes[evt.target.value]})
    }
    
    render(){ 
        if(this.state.klant!=null&&this.state.abonnementTypes!=null){
            let klant = this.state.klant;
            return(<div>
                <div class="grid grid-cols-8">
                        
                    <div class="m-2 col-span-1">
                        {this.state.abonnement
                            ? (<div class="m-4">Abonnement ID: </div>)
                            : null
                        }
                        <div class="m-4 ">DebiteurNr: </div>
                        <div class="m-4 ">Bedrijfsnaam: </div>
                        <div class="m-4 ">Type: </div>
                        <div class="m-4">Aantal: </div>
                        <RenderOnRole roles={["Administrator"]}>
                            <div class="m-4">Startdatum: </div>
                        </RenderOnRole>
                        <div class="m-4">Einddatum: </div>
                    </div>
                    <div class="m-2 col-span-7">
                        {this.state.abonnement
                            ? (<input class="m-2 border border-black bg-gray-200 w-full" type="text" value={this.state.abonnementId} readOnly={true}/>)
                            : null
                        }

                        <input class="m-2 border border-black bg-gray-200 w-full" type="text" value={klant.debiNr} readOnly={true}/>
                        <input class="m-2 border border-black bg-gray-200 w-full" type="text" value={klant.naam} readOnly={true}/>

                        <select name="selectAbonnement" class="m-2 border border-black w-full" onChange={evt=>{this.SetAbonnementValue(evt)}} value={this.SelectAbonnementOption()}>
                            {this.state.abonnementTypes.map((abo,i)=>(<option value={i}>{abo.naam}</option>))}
                        </select>

                        <input name="selectAantal" type="number" class="m-2 border border-black w-full" onChange={evt=>{this.state.aantal=evt.target.value}} defaultValue={this.state.aantal? this.state.aantal:1}/>

                        <RenderOnRole roles={["Administrator"]}>
                            <input name="selectStartdate" type="date" class="m-2 border border-black w-full" onChange={evt=>{this.state.startdatum=evt.target.value}} defaultValue={this.state.startdatum? this.state.startdatum.toString().split("T")[0]:null}/>
                        </RenderOnRole>
                        <input name="selectEndDate" type="date" class="m-2 border border-black w-full" onChange={(evt)=>{this.state.einddatum=evt.target.value}} defaultValue={this.state.einddatum? this.state.einddatum.toString().split("T")[0]:null}/>
                        <div class="text-red-500" name="responseMessage">{this.state.responseMessage}</div>
                        <button name="btnSubmit" class="bg-blue-200 m-2 mt-5 border border-black rounded-lg" onClick={()=>{this.PostAbonnement()}}>
                            {this.state.update?"Abonnement Updaten":"Abonnement Toevoegen"}
                        </button>
                        {this.state.redirect ? (<Redirect to={"/klant/"+this.state.klant.klantId}/>):null}
                    </div>
                </div>
            </div>)
        }
        if(this.state.error){
            return(<div>Abonnementtypes kunnen niet opgehaald worden.</div>)
        }
        if(this.state.loading){
            return(<div>Loading...</div>)
        }
        return(<div>Geen klant gekozen.</div>)
    }
}
export default AbonnementToevoegenPagina;