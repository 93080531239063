import React, { Component } from 'react';
import UserService from '../services/UserService';

class Logout extends Component {


  render() {
    
      return (
        <button class="font-bold inline px-2 text-xl border-l-2 border-gray-500 h-full" onClick={ () => UserService.doLogout()}>
              Logout
        </button>
      );
    
  }
}
export default Logout;