import React, {Component, useState} from "react";
import '../index.css';
import Collapsible from '../Components/KlantenPagina/KlantCollapsible.js';
import { Redirect,Link } from "react-router-dom";
import HttpService from "../services/HttpService";
import { APIUrl } from "../Globals";

class KlantenPagina extends Component{
    state={
        loading: false,
        klanten:null,
        displayKlanten:null,
        selectedKlanten:null,
        error:false,
        actieveKlanten:true
    }

    componentDidMount() {
        let url = APIUrl + `/Klanten`;
        const httpService = HttpService;
        httpService.configure();
        const axios = httpService.getAxiosClient();
        
        this.setState({
            loading: true,
            klanten: null,
            selectedKlanten: null,
            error: false
        })
        

        axios.get(url).then( response =>{
            this.setState({
                loading: false,
                klanten: response.data,
                //displayKlanten:response.data.filter(klant=>klant.abonnementen.evry(abonnement=> (abonnement.einddatum==null||abonnement.einddatum.getTime()>new Date().getTime()))),
                selectedKlanten: null,
                error: false
            })
            this.SetDisplayKlanten()
            this.OrderByNaam();
        }).catch(()=>{
            this.setState({
                loading: false,
                klanten: null,
                selectedKlanten: null,
                error: true
            })
        })
    }

    SetDisplayKlanten(){
        let displayklanten = [];
        let klanten = this.state.klanten;
        for(var k = 0;k<klanten.length;k++){
            let currdate = new Date().getTime();
            let klantValid = false;

            for(var a =0;a<klanten[k].abonnementen.length;a++){
                let abodate = new Date(klanten[k].abonnementen[a].einddatum).getTime();
                if(klanten[k].abonnementen[a].einddatum==null||abodate>currdate){
                    klantValid=true;
                }
            }
            if(klantValid){
                displayklanten.push(klanten[k]);
            }

            // if(klanten[0].abonnementen.evry(abonnement=> (abonnement.einddatum==null||abodate>currdate))){
            //     displayklanten.push(klanten[0]);
            // }
        }
        
        this.setState({
            displayKlanten: displayklanten
        })
    }

    onchangeSearchHandler(target){
        var targetName = target.name;
        var targetValue = target.value;
        if(targetValue==null || targetValue.length<1){
            this.state.selectedKlanten = this.state.displayKlanten;
        }
        else if(targetName=="txtRelatieNr"){
            this.state.selectedKlanten = this.state.displayKlanten.filter(function(klant){return klant.relaNr.toString().includes(targetValue)})
        }
        else if(targetName=="txtBedrijfsnaam"){
            this.state.selectedKlanten = this.state.displayKlanten.filter(function(klant){return klant.naam.toString().toLowerCase().includes(targetValue.toLowerCase())})
        }
        this.forceUpdate();
    }

    OrderByRelanr(){
        this.setState({
            displayKlanten:this.state.displayKlanten.sort(compare)
        })

        function compare( a, b ) {
            if ( a.relaNr < b.relaNr ){
              return -1;
            }
            if ( a.relaNr > b.relaNr ){
              return 1;
            }
            return 0;
          }
    }

    OrderByNaam(){
        this.setState({
            displayKlanten:this.state.displayKlanten.sort(compare)
        })

        function compare( a, b ) {
            if ( a.naam < b.naam ){
              return -1;
            }
            if ( a.naam > b.naam ){
              return 1;
            }
            return 0;
          }
    }

    SwitchActieveKlanten(){
        let newDisplayKlanten = [];
        for(let i =0;i<this.state.klanten.length;i++){
            let index = this.state.displayKlanten.indexOf(this.state.klanten[i]);
            if(index==-1){
                newDisplayKlanten.push(this.state.klanten[i]);
            }
        }
        this.setState({
            displayKlanten: this.state.klanten.filter(klant=>this.state.displayKlanten.indexOf(klant)==-1),
            actieveKlanten: !this.state.actieveKlanten,
            selectedKlanten:null
        })
    }

    render(){
        if(this.state.klanten!=null&&this.state.displayKlanten!=null){
            var klanten;
            if(this.state.selectedKlanten!=null){
                klanten = this.state.selectedKlanten;
            }
            else{
                klanten = this.state.displayKlanten;
            }
    
            return(
                <div className="grid grid-cols-12 border-gray-400 border-2 mt-2">
                    <h3 style={{backgroundColor: '#00aaad'}} className="col-span-12 p-3 text-center font-semibold mb-5 border-gray-400 border-b-2">Klanten</h3>
                    <div className="col-span-2 m-2">
                        {/* <Link to="/klanttoevoegen"><button class="w-3/4 col-span-1 bg-blue-200 m-2 border border-black rounded-md" name="btnKlant">Klant toevoegen</button>
                        </Link> */}
                        <div className="m-2">Zoekopties:</div>
                        <input className="border border-black m-2" onChange={e=>this.onchangeSearchHandler(e.target)} type="text" name="txtRelatieNr" placeholder=" relatieNr" ></input>
                        <input className="border border-black m-2" onChange={e=>this.onchangeSearchHandler(e.target)} type="text" name="txtBedrijfsnaam" placeholder=" bedrijfsnaam" ></input><br/>
                        Actieve klanten: 
                        <input className="m-2" label="Actieve klanten: " type="checkbox" checked={this.state.actieveKlanten} onChange={()=>this.SwitchActieveKlanten()}/>
                    </div>
                    <div className="col-span-10 mr-2">
                        
                        <table style={{textAlign:"left"}} className="table-fixed bg-blue-100 border-2 border-gray-600 w-full" name="klantenTable">
                            <thead>
                                <tr>
                                    <th className="w-2/5" onClick={()=>this.OrderByNaam()}>Naam</th>
                                    <th className="w-1/5" onClick={()=>this.OrderByRelanr()}>RelaNr</th>
                                    {/* <th class="w-1/5">Aantal abonnementen</th> */}
                                    <th className="w-1/5"></th>
                                </tr>
                            </thead>
                            
                            {klanten && klanten.map((klant,index) =>
                                <Collapsible klant = {klant} index = {index}/>
                            )}
                            
                        </table>
                    </div>
                </div>
            )
        }
        else if(this.state.error){
            return(
            <div>
                <div>Geen klanten gevonden.</div>
            </div>)
        }
        else{

            return(
                <div>
                    loading...
                </div>
            )

        }

    }
}
export default KlantenPagina;