import React, {Component} from "react";
import { Redirect } from "react-router";

class InterfaceOverzichtPagina extends Component{

    componentDidMount(){
        this.setState({
            interface: this.props.location.state.selectedInterface,
            abonnement: this.props.location.state.abonnement,
            redirect: false
        })
    }


    render(){ 
        if(this.state.redirect){
            return(<Redirect to={"/"}/>)
        }
        if(this.state.interface!=null&&this.state.abonnement!=null){
            var selectedInterface = this.state.interface;
            var abonnement = this.state.abonnement;
            return(<div>
                {/* <div class="grid grid-cols-8 m-4">
                        <button class="bg-blue-200 m-2 mt-5 border border-black" onClick={()=>{this.setState({redirect:true})}}>
                            Terug
                        </button>

                        <div class="m-2 col-span-1">ID: </div>
                        <input class="m-2 col-span-7 border border-black bg-gray-200 w-full" type="text" value={interface.interfaceId} readOnly={true}/>
                        
                        <div class="m-2 col-span-1">Aanmaakdatum: </div>
                        <input class="m-2 col-span-7 border border-black bg-gray-200 w-full" type="text" value={interface.aanmaakdatum.toString().split("T")[0]} readOnly={true}/>

                        <div class="m-2 col-span-1">Valt onder abonnement: </div>
                        <input name="selectAbonnement" class="m-2 col-span-7 border border-black w-full" type="text" value={abonnement.abonnementType.mkg_artikelzoekcode} readOnly={true}/>

                        <div class="m-2 col-span-1">Artikelnr: </div>
                        <input name="selectArtikel" class="m-2 col-span-7 border border-black w-full" value={interface.artikelNr} readOnly={true}/>

                        <div class="m-2 col-span-1">Postzegel: </div>
                        <input name="selectPostzegel" class="m-2 col-span-7 border border-black w-full" type="text" value={interface.postzegel.code} readOnly={true} />

                        <div class="m-2 col-span-1">Startdatum: </div>
                        <input name="selectStartdate" type="date" class="m-2 col-span-7  border border-black w-full" value={interface.startdatum} readOnly={true}/>

                        <div class="m-2 col-span-1">Einddatum: </div>
                        <input name="selectEnddate" type="date" class="m-2 col-span-7 border border-black w-full" value={interface.einddatum} readOnly={true}/>

                        <div class="m-2 col-span-1">Status: </div>
                        <input name="selectStatus" class="m-2 border col-span-7 border-black w-full" type="text" value={interface.status} readOnly={true}/>

                        <div class="m-2 col-span-1">MKG relanr verzender: </div>
                        <select name="mkgVerzender" class="m-2 col-span-7 border border-black w-full" type="text" value={interface.mkg_relaNr_verzend} readOnly={true}/>

                        <div class="m-2 col-span-1">MKG relanr ontvangt:* </div>
                        <select name="mkgOntvanger" class="m-2 col-span-7 border border-black w-full" type="text" value={interface.mkg_relaNr_ontvangt} readOnly={true}/>

                        <div class="m-2 col-span-1">Refresh token: </div>
                        <input name="refreshtoken" class="m-2 col-span-7 border border-black bg-gray-200 w-full" type="text" defaultValue={this.state.refreshToken} readOnly={true}></input>

                        <div class="m-2 col-span-1">SFTP </div>
                        <div class="col-span-7 grid grid-cols-6 justify-items-start">
                            <div class="m-2 col-span-1">Host: </div>
                            <input name="sftphost" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.state.sftpHost=evt.target.value}} defaultValue={this.state.sftpHost}></input>

                            <div class="m-2 col-span-1 justify-items-center">Poort: </div>
                            <input name="sftppoort" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.state.sftpPort=evt.target.value}} defaultValue={this.state.sftpPort}></input>

                            <div class="m-2 col-span-1">Map: </div>
                            <input name="sftpmap" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.state.sftpMap=evt.target.value}} defaultValue={this.state.sftpMap}></input>

                            <div class="m-2 col-span-1">Gebruikersnaam: </div>
                            <input name="sftpuser" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.state.sftpUsername=evt.target.value}} defaultValue={this.state.sftpUsername}></input>

                            <div class="m-2 col-span-1">Wachtwoord: </div>
                            <input name="sftppassword" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.state.sftpPassword=evt.target.value}} defaultValue={this.state.sftpPassword}></input>
                        </div>



                        <div class="m-2 col-span-1">Gebruikt Nokavision:</div>
                        <input type="checkbox" class="m-2 col-span-7 border border-black w-full" defaultChecked={this.state.gebruiktNokavision} onChange={evt=>{this.state.gebruiktNokavision=evt.target.checked}}/>
                        
                        <div class="m-2 col-span-1">PDF map: </div>
                        <input name="pdfMap" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.PDFMap=evt.target.value}} defaultValue={this.state.PDFMap}></input>
                        
                        <div class="m-2 col-span-1">Connectie type: </div>
                        <input name="connectieType" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.connectionType=evt.target.value}} defaultValue={this.state.connectionType}></input>

                        <div class="m-2 col-span-1">GLN code: </div>
                        <input name="glnCode" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.GLNCode=evt.target.value}} defaultValue={this.state.GLNCode}></input>

                        <div class="m-2 col-span-1">Opmerkingen: </div>
                        <input name="opmerkingen" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.Note=evt.target.value}} defaultValue={this.state.Note}></input>

                        <div class="m-2 col-span-1">Credentials: </div>
                        <input name="credentials" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.Creadentials=evt.target.value}} defaultValue={this.state.Creadentials}></input>

                        <div class="m-2 col-span-1">E-mail: </div>
                        <input name="email" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.mail=evt.target.value}} defaultValue={this.state.mail}></input>

                        <div class="m-2 col-span-1">Dynamische listener: </div>
                        <input name="dynamische listener" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.state.DynamicListener=evt.target.value}} defaultValue={this.state.DynamicListener}></input>

                        
                    
                    <div class="m-2 col-span-7">
     
                    </div> */}
                {/* </div> */}
            </div>)
        }
        if(this.state.error){
            return(<div>Er is iets mis gegaan: {this.state.error}</div>)
        }
        if(this.state.loading){
            return(<div>Loading...</div>)
        }
        return(<div>Interface niet beschikbaar.</div>)
    }
}
export default InterfaceOverzichtPagina;