import React, { Component, useState } from "react";
import HttpService from "../services/HttpService";
import "../index.css";
import fileDownload from "js-file-download";
import { APIUrl } from "../Globals";
import Collapsible from "../Components/Betalingen/BetalingenCollapsible";

class BetalingenPagina extends Component {
  state = {
    loading: false,
    error: null,
    betalingenStatus: null,
    abonnementData: null,
    postzegelData: null,
    month: new Date().getFullYear() + "-" + new Date().getMonth(),
  };
  componentDidMount() {
    const httpService = HttpService;
    httpService.configure();
    this.state.axios = httpService.getAxiosClient();

    let url = APIUrl + `/Betalingen`;
    this.setState({
      loading: true,
      betalingenStatus: null,
      error: null,
    });
    this.state.axios
      .get(url)
      .then((response) => {
        this.setState({
          betalingenStatus: response.data.betalingen,
          abonnementData: response.data.abonnementData,
          postzegelData: response.data.postzegelData,
          error: null,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          betalingenStatus: null,
          error: err.message,
          loading: false,
        });
      });
  }

  GetBetalingenExport() {
    let url =
      APIUrl +
      `/Betalingen?jaar=` +
      this.state.month.split("-")[0] +
      "&maand=" +
      this.state.month.split("-")[1] +
      "&export=true";
    this.setState({
      loading: true,
      error: null,
    });
    this.state.axios
      .get(url, "blob")
      .then((response) => {
        fileDownload(
          response.data,
          "BetalingenExport_" + this.state.month + ".xml"
        );

        this.setState({
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        this.setState({
          error:
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message,
          loading: false,
        });
      });
  }

  RetryExport() {
    let url = APIUrl + `/Betalingen`;
    this.setState({
      loading: true,
      error: null,
    });
    this.state.axios
      .post(url)
      .then((response) => {
        this.setState({
          betalingenStatus: response.data.betalingen,
          error: null,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          error:
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message,
          loading: false,
        });
      });
  }

  MonthSelected(evt) {
    this.state.month = evt.target.value;
    this.UpdateBetalingenInfo();
  }

  UpdateBetalingenInfo() {
    let url =
      APIUrl +
      `/Betalingen?jaar=` +
      this.state.month.split("-")[0] +
      "&maand=" +
      this.state.month.split("-")[1] +
      "&export=false";
    this.setState({
      loading: true,
      error: null,
    });
    this.state.axios
      .get(url)
      .then((response) => {
        this.setState({
          abonnementData: response.data.abonnementData,
          postzegelData: response.data.postzegelData,
          error: null,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          betalingenStatus: null,
          error: err.message,
          loading: false,
        });
      });
  }

  render() {
    if (
      !this.state.loading &&
      !this.state.error &&
      this.state.betalingenStatus != null
    ) {
      let betalingenStatus = this.state.betalingenStatus;
      let abonnementData = this.state.abonnementData;
      abonnementData = abonnementData.sort((a, b) =>
        a.count > b.count ? -1 : 1
      );
      let postzegelData = this.state.postzegelData;
      postzegelData = postzegelData.sort((a, b) =>
        a.count > b.count ? -1 : 1
      );
      return (
        <div class="grid grid-cols-8 m-4">
          <div class="col-span-1" />
          <div class="col-span-7">
            <div>
              Laatste betalingen export: {betalingenStatus.datum}
              <br />
              Status: {betalingenStatus.http_code}{" "}
              {betalingenStatus.http_bericht}
            </div>
            <div class="mt-3">
              Handmatige export:
              <br />
              <input
                type="month"
                onChange={(evt) => this.MonthSelected(evt)}
                defaultValue={this.state.month}
              />
              <br />
              <button
                class="col-span-1 h-10 bg-blue-200 m-2 mt-5 border border-black rounded-lg w-24"
                onClick={() => this.GetBetalingenExport()}
                value="Export"
              >
                Export
              </button>
              <button
                class="col-span-1 h-10 bg-blue-200 m-2 mt-5 border border-black rounded-lg w-24"
                onClick={() => this.RetryExport()}
                value="Retry"
              >
                Retry
              </button>
            </div>
          </div>
          <div class="col-span-1"></div>
          <div class="col-span-3">
            <table>
              <thead>
                <tr>
                  <th>Abonnement naam</th>
                  <th>Aantal</th>
                </tr>
              </thead>
              {abonnementData.map((abo) => (
                <Collapsible item={abo} />
              ))}
            </table>
          </div>
          <div class="col-span-3">
            <table>
              <thead>
                <tr>
                  <th>Postzegel naam</th>
                  <th>Aantal</th>
                </tr>
              </thead>
              {postzegelData.map((zegel) => (
                <Collapsible item={zegel} />
              ))}
            </table>
          </div>
        </div>
      );
    }
    if (this.state.error) {
      return <div>Er is iets mis gegaan: {this.state.error}</div>;
    }
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return <div>Betalingen niet beschikbaar.</div>;
  }
}
export default BetalingenPagina;
