import React, { Component, useState } from "react";
import HttpService from "../services/HttpService";
import { APIUrl } from "../Globals";
import "../index.css";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import RenderOnRole from "../Utilities/RenderOnRole";
import BerichtRow from "../Components/DashboardKlant/BerichtRow";

class BerichtDownloadPagina extends Component {
  state = {
    loading: false,
    error: null,
    interfaceId: null,
    berichten: null,
    displayBerichten: null,
    messageData: null,
    sorting: "",
  };

  componentDidMount() {
    const httpService = HttpService;
    httpService.configure();
    this.state.axios = httpService.getAxiosClient();

    this.state.interfaceId = this.props.id;
    let url =
      APIUrl +
      `/Berichten?InterfaceId=` +
      this.state.interfaceId +
      `&voorMaand=true`;
    this.setState({
      loading: true,
      berichten: null,
      error: null,
    });
    this.state.axios
      .get(url)
      .then((response) => {
        this.setState({
          berichten: JSON.parse(JSON.stringify(response.data)),
          displayBerichten: JSON.parse(JSON.stringify(response.data)),
          error: null,
          loading: false,
        });
        this.props.scroll();
      })
      .catch((err) => {
        this.setState({
          berichten: null,
          error: err.message,
          loading: false,
        });
      });
  }

  onchangeSearchHandler(target) {
    var targetName = target.name;
    var targetValue = target.value;
    if (targetValue == null || targetValue.length < 1) {
      this.state.displayBerichten = this.state.berichten;
    }
    if (targetName == "txtFunctioneelId") {
      let newDisplayBerichten = [...this.state.berichten].filter(function (
        bericht
      ) {
        return bericht.functioneelID.toString().includes(targetValue);
      });

      this.setState({
        displayBerichten: newDisplayBerichten,
      });
    }
  }

  handleHeaderClick(header) {
    let berichten = this.state.displayBerichten;
    let newdata = [];
    if (this.state.sorting == header) {
      if (header == "verzonden_datumtijd") {
        newdata = [...berichten].sort(
          (a, b) => new Date(b[header]) - new Date(a[header])
        );
      } else if (
        header == "displayNaamVerzend" ||
        header == "displayNaamOntvangt" ||
        header == "status"
      ) {
        newdata = [...berichten].sort((a, b) =>
          String(b[header]).localeCompare(String(a[header]))
        );
      } else {
        newdata = [...berichten].sort((a, b) => b[header] - a[header]);
      }
      header = "";
    } else {
      if (header == "verzonden_datumtijd") {
        newdata = [...berichten].sort(
          (a, b) => new Date(a[header]) - new Date(b[header])
        );
      } else if (
        header == "displayNaamVerzend" ||
        header == "displayNaamOntvangt" ||
        header == "status"
      ) {
        newdata = [...berichten].sort((a, b) =>
          String(a[header]).localeCompare(String(b[header]))
        );
      } else {
        newdata = [...berichten].sort((a, b) => a[header] - b[header]);
      }
    }
    this.setState({
      displayBerichten: newdata,
      sorting: header,
    });
  }

  render() {
    if (
      !this.state.loading &&
      !this.state.error &&
      this.state.berichten != null
    ) {
      let berichten = this.state.displayBerichten;
      return (
        <div class="grid grid-cols-8 m-4">
          <div class="col-span-8">
            <div class="m-2 font-bold">Berichten</div>
            <input
              class="border border-black m-2"
              onChange={(e) => this.onchangeSearchHandler(e.target)}
              type="text"
              name="txtFunctioneelId"
              placeholder="FunctioneelId"
            />
            <div class="mt-3">
              <div class="inline-block rounded-lg w-full bg-blue-100 border-2 border-black shadow-2xl">
                <Table name="BerichtenTable" class="w-full">
                  <TableHead>
                    <TableRow class="bg-blue-200" style={{ textAlign: "left" }}>
                      <TableCell
                        class="font-semibold"
                        style={{ paddingLeft: "15px" }}
                        onClick={() =>
                          this.handleHeaderClick("displayNaamVerzend")
                        }
                      >
                        Verzender naam
                      </TableCell>
                      <TableCell
                        class="font-semibold"
                        style={{ paddingLeft: "15px" }}
                        onClick={() => this.handleHeaderClick("glN_zender")}
                      >
                        SCSN nr
                      </TableCell>
                      <TableCell
                        class="font-semibold "
                        style={{ paddingLeft: "15px" }}
                        onClick={() =>
                          this.handleHeaderClick("displayNaamOntvangt")
                        }
                      >
                        Ontvanger naam
                      </TableCell>
                      <TableCell
                        class="font-semibold"
                        style={{ paddingLeft: "15px" }}
                        onClick={() => this.handleHeaderClick("glN_ontvanger")}
                      >
                        SCSN nr
                      </TableCell>
                      <TableCell
                        class="font-semibold "
                        style={{ paddingLeft: "15px" }}
                        onClick={() => this.handleHeaderClick("functioneelID")}
                      >
                        FunctioneelID
                      </TableCell>
                      <TableCell
                        class="font-semibold "
                        style={{ paddingLeft: "15px" }}
                        onClick={() => this.handleHeaderClick("status")}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        class="font-semibold "
                        style={{ paddingLeft: "15px" }}
                        onClick={() =>
                          this.handleHeaderClick("verzonden_datumtijd")
                        }
                      >
                        Datum
                      </TableCell>
                      <RenderOnRole roles={["SD_Medewerker", "Administrator"]}>
                        <TableCell
                          class="font-semibold "
                          style={{ paddingLeft: "15px" }}
                          onClick={() => this.handleHeaderClick("agentname")}
                        >
                          Agent
                        </TableCell>
                        <TableCell
                          class="font-semibold "
                          style={{ paddingLeft: "15px" }}
                        ></TableCell>
                        <TableCell
                          class="font-semibold "
                          style={{ paddingLeft: "15px" }}
                        ></TableCell>
                      </RenderOnRole>
                      <RenderOnRole roles={["Download"]}>
                        <TableCell
                          class="font-semibold "
                          style={{ paddingLeft: "15px" }}
                        ></TableCell>
                      </RenderOnRole>
                      <RenderOnRole roles={["Retry"]}>
                        <TableCell
                          class="font-semibold "
                          style={{ paddingLeft: "15px" }}
                        ></TableCell>
                      </RenderOnRole>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.displayBerichten.length > 0
                      ? this.state.displayBerichten.map(
                          (bericht, index) => (
                            console.log(bericht),
                            (
                              <BerichtRow
                                key={index}
                                bericht={bericht}
                                interfaceId={this.state.interfaceId}
                              />
                            )
                          )
                        )
                      : null}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.error) {
      return <div>Er is iets mis gegaan: {this.state.error}</div>;
    }
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return <div>Berichten niet beschikbaar.</div>;
  }
}
export default BerichtDownloadPagina;
