import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../Images/Supply-Drive-website.jpg";
import RenderOnRole from "../Utilities/RenderOnRole";
import Logout from "./Logout";

function Header() {
  let history = useHistory();

  return (
    <header className="border border-gray-500 bg-gray-200">
      <img src={logo} width="150" height="50" className="inline" />
      {/* <h1 className="font-bold inline text-xl ml-2 mr-2 h-full">
                Supplydrive Portaal
            </h1> */}
      <RenderOnRole roles={["Klant"]}>
        <button
          className="font-bold inline px-2 text-xl h-full"
          onClick={() => {
            history.push({ pathname: "/DashboardKlant" });
          }}
        >
          Dashboard
        </button>
      </RenderOnRole>
      <RenderOnRole
        roles={["SD_Medewerker", "Administrator"]}
        className="h-full"
      >
        <button
          className="font-bold inline px-2 text-xl h-full"
          onClick={() => {
            history.push({ pathname: "/Klanten" });
          }}
        >
          Klanten
        </button>

        <button
          className="font-bold inline px-2 text-xl border-l-2 border-gray-500 h-full"
          onClick={() => {
            history.push({ pathname: "/DashboardKlant" });
          }}
        >
          Dashboard
        </button>

        <button
          className="font-bold inline px-2 text-xl border-l-2 border-gray-500 h-full"
          onClick={() => {
            history.push({ pathname: "/Planning" });
          }}
        >
          Planning
        </button>

        <RenderOnRole roles={["Administrator"]}>
          <button
            className="font-bold inline px-2 text-xl border-l-2 border-gray-500 h-full"
            onClick={() => {
              history.push({ pathname: "/Betalingen" });
            }}
          >
            Betalingen
          </button>
        </RenderOnRole>
      </RenderOnRole>
      <Logout />
    </header>
  );
}
export default Header;
