import React, { Component, useState } from "react";
import HttpService from "../services/HttpService";
import "../index.css";
import UserService from "../services/UserService";
import AbonnementCollapsibleKlant from "../Components/DashboardKlant/AbonnementCollapsibleKlantDashboard";
import { APIUrl } from "../Globals";
import { Redirect } from "react-router-dom";
import Months from "../Enum/Months";
import NieuweInterfacesTabel from "../Components/DashboardKlant/NieuweInterfacesTabel";
import BerichtenGraph from "../Components/DashboardKlant/BerichtenGraph";
import Select from "react-select";
import BerichtDownloadPagina from "./BerichtDownloadPagina";

class DashboardKlant extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      klanten: null,
      abonnementen: null,
      displayAbonnementen: null,
      jaarAbonnementen: null,
      statistieken: null,
      error: false,
      keycloak: null,
      authenticated: false,
      selectMessage: null,
      graphOptions: {
        data: null,
        uniqueYears: null,
        uniqueMonths: null,
        selectedYear: 0,
        selectedMonth: 0,
      },
      newInterfaces: null,
      latestMessages: null,
      selectedInterface: null,
      showInactive: true,
      redirect: false,
      selectedYear: 0,
      selectedMonth: 0,
      selectedWeek: 0,
      showInactiveButton: true,
      selectedCustomerIndex: 0,
      showBerichten: false,
    };

    this.changeChartData = this.changeChartData.bind(this);
    this.changeChartDataAbo = this.changeChartDataAbo.bind(this);
    this.resetData = this.resetData.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    let axios = HttpService.getAxiosClient();
    let debiNr = UserService.getDebiNr();
    if (debiNr != null) {
      let url =
        APIUrl +
        `/klanten?` +
        debiNr.map(function (number) {
          return "debiNr=" + number + "&";
        });
      url = url.slice(0, -1);
      url = url.replaceAll(",", "");
      this.setState({
        loading: true,
        klanten: null,
        error: false,
      });
      axios
        .get(url)
        .then((response) => {
          this.setState({
            loading: false,
            klanten: response.data.sort((a, b) => (a.naam > b.naam ? 1 : -1)),
            error: false,
          });

          if (this.state.klanten.length > 0) {
            this.changeCustomer(0);
          }
          this.forceUpdate();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            klanten: null,
            error: true,
            errorMessage: error.message,
          });
        });
    }
  }

  SetCustomerData(index) {
    let selectedKlantDebinr = this.state.klanten[index].debinr;

    let axios = HttpService.getAxiosClient();
    let debiNr = UserService.getDebiNr();
    if (debiNr != null) {
      let url = APIUrl + `/Statistieken?debiNr=` + selectedKlantDebinr;
      axios
        .get(url)
        .then((response) => {
          let newKlanten = this.state.klanten;
          newKlanten[index] = response.data[0];
          this.setState({
            loading: false,
            klanten: newKlanten,
            error: false,
          });
          this.changeCustomerLogic(index);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            klanten: null,
            error: true,
            errorMessage: error.message,
          });
        });
    }
  }

  changeCustomer(index) {
    if (this.state.klanten[index].abonnementen.length < 1) {
      this.SetCustomerData(index);
    } else {
      this.changeCustomerLogic(index);
    }
  }

  changeCustomerLogic(index) {
    let now = new Date();
    var newDate = new Date(now.setMonth(now.getMonth() - 1));
    now = new Date();

    this.state.newInterfaces = [];
    this.state.klanten[index].abonnementen.map((abonnement) => {
      return abonnement.interfaces.map((_interface) => {
        let interfaceTime = Date.parse(_interface.startdatum);
        if (
          _interface.startdatum == null ||
          (interfaceTime >= newDate.getTime() && interfaceTime <= now.getTime())
        ) {
          const newInterface = new Object();
          newInterface.interface = _interface;
          newInterface.abonnementNaam = abonnement.naam;
          this.state.newInterfaces.push(newInterface);
        }
      });
    });

    let abonnementen = this.state.klanten[index].abonnementen;
    for (let i = 0; i < abonnementen.length; i++) {
      abonnementen[i].key = Math.floor(Math.random() * 1000);
    }
    this.setState({
      abonnementen: abonnementen,
      displayAbonnementen: this.state.klanten[index].abonnementen,
      selectedCustomer: this.state.klanten[index],
      graphOptions: {
        data: this.state.klanten[index].statistieken.berichtenPerMaand,
        uniqueYears: [
          ...new Set(
            this.state.klanten[index].statistieken.berichtenPerMaand.map(
              (item) => item.jaar
            )
          ),
        ],
        selectedYear: 0,
      },
      newInterfaces: this.state.newInterfaces,
      selectMessage: null,
      showInactive: true,
      selectedCustomerIndex: index,
      showBerichten: false,
    });
  }

  changeChartData(selectedInterface) {
    let message = "Geselecteerde interface = " + selectedInterface.omschrijving;

    this.setState({
      selectMessage: message,
      graphOptions: {
        data: selectedInterface.berichtenPerMaand,
        uniqueYears: this.state.graphOptions.uniqueYears,
        selectedYear: this.state.graphOptions.selectedYear,
        uniqueMonths: this.state.graphOptions.uniqueMonths,
        selectedMonth: this.state.graphOptions.selectedMonth,
      },
      selectedInterface: selectedInterface,
      showBerichten: false,
    });
  }

  changeChartDataAbo(selectedAbonnement) {
    let message = "Geselecteerd abonnement = " + selectedAbonnement.naam;

    this.setState({
      selectMessage: message,
      graphOptions: {
        data: selectedAbonnement.berichtenPerMaand,
        uniqueYears: this.state.graphOptions.uniqueYears,
        selectedYear: this.state.graphOptions.selectedYear,
        uniqueMonths: this.state.graphOptions.uniqueMonths,
        selectedMonth: this.state.graphOptions.selectedMonth,
      },
    });
  }

  resetData() {
    this.setState({
      graphOptions: {
        data: this.state.selectedCustomer.statistieken.berichtenPerMaand,
        uniqueYears: this.state.graphOptions.uniqueYears,
        selectedYear: this.state.graphOptions.selectedYear,
        uniqueMonths: this.state.graphOptions.uniqueMonths,
        selectedMonth: this.state.graphOptions.selectedMonth,
      },
      selectMessage: null,
      selectedInterface: null,
      showBerichten: false,
    });
  }

  changeYear(evt) {
    this.updateDisplayAbonnementen(
      evt.target.value,
      0,
      this.state.showInactive
    );
  }

  changeMonth(evt) {
    this.updateDisplayAbonnementen(
      this.state.selectedYear,
      evt.target.value,
      this.state.showInactive
    );
  }

  changeActive() {
    this.updateDisplayAbonnementen(
      this.state.selectedYear,
      this.state.selectedMonth,
      !this.state.showInactive
    );
  }

  updateDisplayAbonnementen(selectedYear, selectedMonth, showInactive) {
    let abonnementen = JSON.parse(JSON.stringify(this.state.abonnementen));
    let displayAbonnementen = abonnementen;

    let berichtenPerMaand =
      this.state.selectedCustomer.statistieken.berichtenPerMaand;

    let showInactiveButton = true;

    if (!showInactive) {
      displayAbonnementen = displayAbonnementen.filter(
        (abonnement) => abonnement.status === "Actief"
      );
    }

    let uniqueMonths;

    if (selectedYear > 0) {
      showInactiveButton = false;

      displayAbonnementen = displayAbonnementen.filter(function (abo) {
        return (
          abo.startdatum != null &&
          abo.startdatum.split("-")[0] <= selectedYear &&
          (abo.einddatum == null || abo.einddatum >= selectedYear)
        );
      });

      displayAbonnementen = displayAbonnementen.filter(function (abo) {
        return (
          abo.startdatum != null &&
          abo.startdatum.split("-")[0] <= selectedYear &&
          (abo.einddatum == null || abo.einddatum >= selectedYear)
        );
      });
      for (let abo = 0; abo < displayAbonnementen.length; abo++) {
        let remove_interfaces = [];
        for (
          let int = 0;
          int < displayAbonnementen[abo].interfaces.length;
          int++
        ) {
          let _interface = displayAbonnementen[abo].interfaces[int];
          if (
            _interface.startdatum != null &&
            _interface.startdatum.split("-")[0] <= selectedYear &&
            (_interface.einddatum == null ||
              _interface.einddatum >= selectedYear)
          ) {
            //the interface is ok
          } else {
            remove_interfaces.push(_interface);
          }
        }

        for (let remove = 0; remove < remove_interfaces.length; remove++) {
          let index = displayAbonnementen[abo].interfaces.indexOf(
            remove_interfaces[remove]
          );
          if (index !== -1)
            displayAbonnementen[abo].interfaces.splice(index, 1);
        }
      }

      berichtenPerMaand =
        this.state.selectedCustomer.statistieken.berichtenPerMaand.filter(
          (item) => item.jaar == parseInt(selectedYear)
        );
      uniqueMonths = berichtenPerMaand
        .map((item) => item.maand)
        .sort(function (a, b) {
          return a - b;
        });
    }

    // for(let i = 0;i<displayAbonnementen.length;i++){
    //     displayAbonnementen[i].key = Math.floor(Math.random() * 1000);
    // }

    if (selectedMonth > 0) {
      showInactiveButton = false;

      displayAbonnementen = displayAbonnementen.filter(function (abo) {
        return (
          abo.startdatum != null &&
          (parseInt(abo.startdatum.split("-")[1]) <= selectedMonth ||
            parseInt(abo.startdatum.split("-")[0]) < selectedYear) &&
          (abo.einddatum == null || abo.einddatum >= selectedMonth)
        );
      });

      for (let abo = 0; abo < displayAbonnementen.length; abo++) {
        let remove_interfaces = [];
        for (
          let int = 0;
          int < displayAbonnementen[abo].interfaces.length;
          int++
        ) {
          let _interface = displayAbonnementen[abo].interfaces[int];
          if (
            _interface.startdatum != null &&
            (parseInt(_interface.startdatum.split("-")[1]) <=
              parseInt(selectedMonth) ||
              parseInt(_interface.startdatum.split("-")[0]) <
                parseInt(selectedYear)) &&
            (_interface.einddatum == null ||
              _interface.einddatum.split("-")[1] >= selectedMonth ||
              _interface.einddatum.split("-")[0] > selectedYear)
          ) {
            //the interface is ok
            console.log(_interface.startdatum);
          } else {
            remove_interfaces.push(_interface);
          }
        }

        for (let remove = 0; remove < remove_interfaces.length; remove++) {
          let index = displayAbonnementen[abo].interfaces.indexOf(
            remove_interfaces[remove]
          );
          if (index !== -1)
            displayAbonnementen[abo].interfaces.splice(index, 1);
        }
      }
    }

    let berichtenPerMaandNew =
      this.calculateBerichtenPerMaand(displayAbonnementen);

    this.setState({
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      showInactive: showInactive,
      displayAbonnementen: displayAbonnementen,
      showInactiveButton: showInactiveButton,
      graphOptions: {
        data: berichtenPerMaandNew,
        uniqueYears: this.state.graphOptions.uniqueYears,
        uniqueMonths: uniqueMonths,
        selectedYear: parseInt(selectedYear),
        selectedMonth: parseInt(selectedMonth),
      },
    });
  }

  calculateBerichtenPerMaand(abonnementen) {
    let berichtenPerMaand = [];
    for (let abo = 0; abo < abonnementen.length; abo++) {
      for (let int = 0; int < abonnementen[abo].interfaces.length; int++) {
        let _interface = abonnementen[abo].interfaces[int];
        for (let ber = 0; ber < _interface.berichtenPerMaand.length; ber++) {
          let bericht = _interface.berichtenPerMaand[ber];
          let index = berichtenPerMaand.findIndex(
            (b) => b.maand == bericht.maand && b.jaar == bericht.jaar
          );
          if (index >= 0) {
            berichtenPerMaand[index].aantal += bericht.aantal;
          } else {
            let berichtCopy = JSON.parse(JSON.stringify(bericht));
            berichtenPerMaand.push(berichtCopy);
          }
        }
      }
    }
    berichtenPerMaand.sort(function (a, b) {
      return new Date(a.datum) - new Date(b.datum);
    });
    return berichtenPerMaand;
  }

  ShowBerichten() {
    this.setState({
      showBerichten: true,
    });
    this.forceUpdate();
  }

  ScrollToBerichten() {
    this.myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  render() {
    let klanten = this.state.klanten;
    if (
      klanten != null &&
      this.state.selectedCustomer != null &&
      this.state.selectedCustomer.statistieken.berichtenPerMaand != null
    ) {
      let year = this.state.graphOptions.selectedYear;
      let month = this.state.graphOptions.selectedMonth;
      let customerOptions = klanten.map((klant, i) => ({
        label: klant.naam,
        value: i,
      }));

      return (
        <div>
          <div class="w-full grid grid-cols-7">
            <div class="col-span-1">
              <div class="m-2 font-bold">
                Gebruiker: {UserService.getUsername()} <br />
              </div>
              {/* <select
                class="ml-2"
                onChange={(evt) =>
                  this.changeCustomer(evt.target.selectedIndex)
                }
              >
                {klanten.map(function (klant, i) {
                  return <option id={i}>{klant.naam}</option>;
                })}
              </select> */}
              <Select
                class="ml-2"
                onChange={(evt) => {
                  this.changeCustomer(evt.value);
                }}
                value={customerOptions[this.state.selectedCustomerIndex]}
                options={customerOptions}
              />

              {this.state.showInactiveButton && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div class="m-2">Inactieve abonnementen:</div>
                  <input
                    class="m-2"
                    type="checkbox"
                    checked={this.state.showInactive}
                    onChange={() => this.changeActive()}
                  />
                </div>
              )}

              <div class="m-2 font-bold">Periode:</div>
              <select
                class="m-2"
                onChange={(evt) => this.changeYear(evt)}
                value={this.state.selectedYear}
              >
                <option value={0}>Jaar</option>
                {this.state.graphOptions.uniqueYears.map((year) => {
                  return <option value={year}>{year}</option>;
                })}
              </select>
              <select
                class="m-2"
                onChange={(evt) => this.changeMonth(evt)}
                value={this.state.selectedMonth}
              >
                <option value={0}>Maand</option>
                {this.state.graphOptions.uniqueMonths != null
                  ? this.state.graphOptions.uniqueMonths.map((month) => {
                      return <option value={month}>{Months[month - 1]}</option>;
                    })
                  : null}
              </select>
            </div>

            {this.state.newInterfaces.length > 0 && (
              <NieuweInterfacesTabel newInterfaces={this.state.newInterfaces} />
            )}

            <div class="col-span-7 m-3">
              <div>
                <div class="font-bold mb-2">Abonnementen</div>
                <table
                  style={{ textAlign: "left" }}
                  name="aboTable"
                  class="table-fixed bg-blue-100 border-2 border-black w-full"
                >
                  <thead>
                    <tr>
                      <th class="w-4/12">Abonnement naam</th>
                      <th class="w-2/12">Startdatum</th>
                      <th class="w-2/12">Einddatum</th>
                      <th class="w-1/12">Status</th>
                      <th class="w-1/12">Interfaces</th>
                      <th class="w-1/12">Aantal berichten</th>
                      <th class="w-1/12"></th>
                    </tr>
                  </thead>

                  {this.state.displayAbonnementen &&
                    this.state.displayAbonnementen.map((abonnement) => (
                      <AbonnementCollapsibleKlant
                        key={abonnement.key}
                        abonnement={abonnement}
                        year={year}
                        month={month}
                        changeData={this.changeChartData}
                        changeDataAbo={this.changeChartDataAbo}
                        resetData={this.resetData}
                      />
                    ))}
                </table>
              </div>
            </div>
          </div>

          <div class="w-full grid grid-cols-4 m-3">
            <div class="col-span-2">
              <BerichtenGraph
                graphOptions={this.state.graphOptions}
                selectMessage={this.state.selectMessage}
                selectedInterface={this.state.selectedInterface}
                reset={this.resetData}
                ShowBerichten={this.ShowBerichten.bind(this)}
              />
            </div>

            {this.state.showInactiveButton ? (
              <div class="col-span-2 mr-6">
                <div class="font-bold mb-3">Laatste berichten</div>
                <div class="inline-block rounded-lg w-full bg-blue-200 border-2 border-black shadow-2xl">
                  <table
                    style={{ textAlign: "left" }}
                    name="berichtTable"
                    class="w-full"
                  >
                    <thead>
                      <tr>
                        <th class="w-5/20">Zender</th>
                        <th class="w-5/20">SCSN nr</th>
                        <th class="w-5/20">Ontvanger</th>
                        <th class="w-5/20">SCSN nr</th>
                        <th class="w-5/20">Datum</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedCustomer.laatsteBerichten.map(
                        (bericht) => (
                          <tr class="border border-black">
                            {bericht.verzenderNaam == "Stichting SCSN" &&
                            bericht.scsN_naam_zender != null ? (
                              <td class="w-5/20">{bericht.scsN_naam_zender}</td>
                            ) : (
                              <td class="w-5/20">{bericht.verzenderNaam}</td>
                            )}
                            <td class="w-5/20">{bericht.glN_zender}</td>
                            {bericht.ontvangerNaam == "Stichting SCSN" &&
                            bericht.scsN_naam_ontvanger != null ? (
                              <td class="w-5/20">
                                {bericht.scsN_naam_ontvanger}
                              </td>
                            ) : (
                              <td class="w-5/20">{bericht.ontvangerNaam}</td>
                            )}
                            <td class="w-5/20">{bericht.glN_ontvanger}</td>
                            <td class="w-5/20">
                              {bericht.verzonden_datumtijd
                                .split("+")[0]
                                .replace("T", " ")}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div ref={this.myRef}>
            {this.state.showBerichten ? (
              <BerichtDownloadPagina
                id={this.state.selectedInterface.id}
                scroll={() => this.ScrollToBerichten()}
              />
            ) : null}
          </div>
        </div>
      );
    } else if (this.state.error) {
      return <div>{this.state.errorMessage}</div>;
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default DashboardKlant;
