import React, {Component, useState} from "react";
import axios from "axios";
import '../index.css';

class KlantToevoegenPagina extends Component{
    render(){ 
        return(<div>
            <div class="grid grid-cols-9">
                <div class="m-2 col-span-1">DebiteurNr: </div>
                <input class="m-2 col-span-1 border border-black" type="text" placeholder="debiteurNr"/>
            </div>
        </div>)
    }
}
export default KlantToevoegenPagina;