import React from "react"

function NieuweInterfacesTabel(props){

    return(
                <div class="col-span-6 m-3 rounded-lg">
                    <div class="mb-2 font-bold">
                        Nieuwe Interfaces
                    </div>
                    <div class="inline-block rounded-lg w-full bg-blue-100 border-2 border-black shadow-2xl">
                        <table name="nieuwInterfacesTable" class="w-full">
                            <thead>
                                <tr class="bg-blue-200">
                                    <td class="font-semibold ">Naam</td>
                                    <td class="font-semibold ">Abonnement naam</td>
                                    <td class="font-semibold ">Verzender</td>
                                    <td class="font-semibold ">Ontvanger</td>
                                    <td class="font-semibold ">Status</td>
                                    <td class="font-semibold ">Startdatum</td>
                                    <td class="font-semibold ">Einddatum</td>
                                    <td class="font-semibold ">Aantal verzonden</td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.newInterfaces.length>0&& props.newInterfaces.map(interfaceObj =>
                                                <tr className ='bg-blue-200 border-black border'>
                                                    <td>{interfaceObj.interface.omschrijving}</td>
                                                    <td>{interfaceObj.abonnementNaam}</td>
                                                    <td>{interfaceObj.interface.verzenderNaam}</td>
                                                    <td>{interfaceObj.interface.ontvangerNaam}</td>
                                                    <td>{interfaceObj.interface.status}</td>
                                                    {interfaceObj.interface.startdatum ? (
                                                        <td>{interfaceObj.interface.startdatum.toString().split("T")[0]}</td>
                                                    ) : (
                                                        <td/>
                                                    )}
                                                    {interfaceObj.interface.einddatum ? (
                                                        <td>{interfaceObj.interface.einddatum.toString().split("T")[0]}</td>
                                                    ) : (
                                                        <td/>
                                                    )}
                                                    <td>{interfaceObj.interface.aantalBerichten}</td>
                                                </tr>
                                        
                                    )}
                                </tbody>
                        </table>
                    </div>
                </div>
    )
}
export default NieuweInterfacesTabel;