import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import KlantenPagina from "./Pages/KlantenPagina";
import Header from "./Components/Header";
import KlantPagina from "./Pages/KlantPagina";
import KlantToevoegenPagina from "./Pages/KlantToevoegenPagina";
import AbonnementToevoegenPagina from "./Pages/AbonnementToevoegenPagina";
import RenderOnRole from "./Utilities/RenderOnRole";
import RenderOnAuthenticated from "./Utilities/RenderOnAuthenticated";
import PrivatePage from "./Pages/PrivatePage";
import RenderOnAnonymous from "./Utilities/RenderOnAnonymous";
import LoginPage from "./Pages/LoginPage";
import DashboardKlant from "./Pages/DashboardKlant";
import InterfaceToevoegenPagina from "./Pages/InterfaceToevoegenPagina";
import InterfaceOverzichtPagina from "./Pages/InterfaceOverzichtPagina";
import BetalingenPagina from "./Pages/BetalingenPagina";
import BerichtDownloadPagina from "./Pages/BerichtDownloadPagina";
import PlanningPagina from "./Pages/PlanningPagina";

function App() {
  return (
    <Router>
      <RenderOnAnonymous>
        <LoginPage />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <Header />
        <RenderOnRole roles={["SD_Medewerker"]}>
          <div className="grid grid-cols-12 h-screen w-full">
            <div className="col-span-1" />
            <div className="col-span-10 bg-gray-100 bg-opacity-75">
              <Switch>
                <Route exact path="/">
                  <KlantenPagina />
                </Route>
                <Route path="/Klanten">
                  <KlantenPagina />
                </Route>
                <Route
                  path="/Klant/interface/:id"
                  component={BerichtDownloadPagina}
                />
                <Route path="/Klant/:id" component={KlantPagina} />
                <Route
                  path="/Klanttoevoegen"
                  component={KlantToevoegenPagina}
                />
                <Route
                  path="/Abonnementtoevoegen"
                  component={AbonnementToevoegenPagina}
                />
                <Route
                  path="/Interfacetoevoegen"
                  component={InterfaceToevoegenPagina}
                />
                <Route path="/DashboardKlant">
                  <DashboardKlant />
                </Route>
                <Route path="/Planning">
                  <PlanningPagina />
                </Route>
                <Route path="/">
                  <KlantenPagina />
                </Route>
              </Switch>
            </div>
            <div className="col-span-1" />
          </div>
        </RenderOnRole>
        <RenderOnRole roles={["Administrator"]}>
          <div className="grid grid-cols-12 h-screen w-full">
            <div className="col-span-1" />
            <div className="col-span-10 bg-gray-100 bg-opacity-75">
              <Switch>
                <Route exact path="/">
                  <KlantenPagina />
                </Route>
                <Route path="/Klanten">
                  <KlantenPagina />
                </Route>
                <Route
                  path="/Klant/interface/:id"
                  component={BerichtDownloadPagina}
                />
                <Route path="/Klant/:id" component={KlantPagina} />
                <Route
                  path="/Klanttoevoegen"
                  component={KlantToevoegenPagina}
                />
                <Route
                  path="/Abonnementtoevoegen"
                  component={AbonnementToevoegenPagina}
                />
                <Route
                  path="/Interfacetoevoegen"
                  component={InterfaceToevoegenPagina}
                />
                <Route path="/Betalingen" component={BetalingenPagina} />
                <Route path="/DashboardKlant">
                  <DashboardKlant />
                </Route>
                <Route path="/Planning">
                  <PlanningPagina />
                </Route>
                <Route path="/">
                  <KlantenPagina />
                </Route>
              </Switch>
            </div>
            <div className="col-span-1" />
          </div>
        </RenderOnRole>
        <RenderOnRole roles={["Klant"]}>
          <div className="grid grid-cols-12 h-screen w-full">
            <div className="col-span-1" />
            <div className="col-span-10 bg-gray-100 bg-opacity-75">
              <Switch>
                <Route exact path="/">
                  <DashboardKlant />
                </Route>
                <Route path="/DashboardKlant">
                  <DashboardKlant />
                </Route>
                <Route
                  path="/Klant/interface/:id"
                  component={BerichtDownloadPagina}
                />
                <Route
                  path="/Interfacetoevoegen"
                  component={InterfaceOverzichtPagina}
                />
                <Route path="/">
                  <DashboardKlant />
                </Route>
              </Switch>
            </div>
            <div className="col-span-1" />
          </div>
        </RenderOnRole>
      </RenderOnAuthenticated>
    </Router>
  );
}
export default App;
