import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import copyIcon from "../../Images/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

function AbonnementCollapsible(props) {
  const [isOpen, setIsOpen] = useState(false);
  let abonnement = props.abonnement;
  let klant = props.klant;
  let interfaces = abonnement.interfaces;
  let history = useHistory();

  return (
    <tbody className="border-2 border-gray-600 justify-items-center">
      <tr
        className="border-1 border-gray-600"
        key={abonnement.abonnementId}
        onClick={() => setIsOpen(!isOpen)}
      >
        <td>{abonnement.abonnementType.naam}</td>
        {abonnement.startdatum ? (
          <td>{abonnement.startdatum.toString().split("T")[0]}</td>
        ) : (
          <td />
        )}
        {abonnement.einddatum ? (
          <td>{abonnement.einddatum.toString().split("T")[0]}</td>
        ) : (
          <td />
        )}
        <td>{abonnement.status}</td>
        <td>{interfaces.length}</td>
        <td
          class="font-semibold"
          onClick={() => {
            history.push({
              pathname: "/Abonnementtoevoegen",
              state: { selectedAbonnement: abonnement, klantObject: klant },
            });
          }}
        >
          Bijwerken
        </td>
      </tr>
      {isOpen && interfaces.length > 0 && (
        <tr class="bg-blue-200">
          <td class="font-semibold ">Artikel Nr</td>
          <td class="font-semibold ">Startdatum</td>
          <td class="font-semibold ">Einddatum</td>
          <td class="font-semibold ">Verzender</td>
          <td class="font-semibold ">Ontvanger</td>
          <td class="font-semibold ">Status</td>
          <td class="font-semibold ">CP agent</td>
          {/* <td class="font-semibold ">CP interface</td> */}
          <td class="font-semibold ">Basic auth</td>
          {/* <td class="font-semibold "></td> */}
        </tr>
      )}
      {isOpen &&
        interfaces != null &&
        interfaces.length > 0 &&
        interfaces.map((interfaceObj) => (
          <tr
            onClick={() => {
              history.push({
                pathname: "/Interfacetoevoegen",
                state: {
                  klantObject: klant,
                  selectedInterface: interfaceObj,
                  abonnement: abonnement,
                },
              });
            }}
            className="bg-blue-200 border-black border"
          >
            <td>{interfaceObj.artikelNr}</td>
            {interfaceObj.startdatum ? (
              <td>{interfaceObj.startdatum.toString().split("T")[0]}</td>
            ) : (
              <td />
            )}
            {interfaceObj.einddatum ? (
              <td>{interfaceObj.einddatum.toString().split("T")[0]}</td>
            ) : (
              <td />
            )}
            <td>{interfaceObj.mkg_relaNr_verzend}</td>
            <td>{interfaceObj.mkg_relaNr_ontvangt}</td>
            <td>{interfaceObj.status}</td>
            <td>{interfaceObj.cp_agent}</td>
            {/* <td>{interfaceObj.cp_interface}</td> */}
            <td style={{ display: "flex" }}>
              {interfaceObj.auth}
              {interfaceObj.auth ? (
                <CopyToClipboard text={interfaceObj.auth}>
                  <button
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      style={{ width: 20, height: 20 }}
                      src={copyIcon}
                      alt="copyIcon"
                    />
                  </button>
                </CopyToClipboard>
              ) : null}
            </td>
            {/* <td>
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                navigator.clipboard.writeText(interfaceObj.auth)}}>
                                                <img style={{ width: 20, height: 20 }} src={copyIcon} alt="copyIcon"/>
                                            </button>
                                        </td> */}
          </tr>
        ))}
      {isOpen && interfaces.length < 1 && <div>-</div>}
    </tbody>
  );
}
export default AbonnementCollapsible;
