import React, { Component } from "react";
import { FcCancel, FcCheckmark } from "react-icons/fc";
import { APIUrl } from "../../Globals";
import HttpService from "../../services/HttpService";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import RenderOnRole from "../../Utilities/RenderOnRole";
import UserService from "../../services/UserService";
import styled from "@emotion/styled";
import { RetryTooltip } from "./RetryTooltip";
import { saveAs } from "file-saver";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #b4cffa;
  }
  &:nth-of-type(even) {
    background-color: #c3d8fa;
  }
  & > td {
    color: black;
    border: black;
  }
`;

class BerichtRow extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      berichtObj: this.props.bericht,
      interfaceId: this.props.interfaceId,
      retrySuccess: null,
      retryError: null,
      key: this.props.key,
      selectedFule: null,
      fileContent: ""
    };
    // Reference to the file input element
    this.fileInputRef = React.createRef();
  }

  componentWillReceiveProps(props) {
    this.setState({
      berichtObj: props.bericht,
      interfaceId: props.interfaceId,
      key: props.key,
    });
  }

  componentDidMount() {
    const httpService = HttpService;
    httpService.configure();
    this.state.axios = httpService.getAxiosClient();
  }

  handleFileUpload = (connectmessageId, e) => {
    const { files } = e.target;
    if (files && files.length) {
      this.ResendMessage();
    }
  };

  ResendMessage(connectmessageId, event, newMessage) {
    event.stopPropagation();
    let message = "";
    if(newMessage){
      if(this.state.fileContent){
        message = this.state.fileContent;
      }
      else{
        this.setState({
          retrySuccess: null,
          retryError: "Geen geldig bestand geselecteerd.",
          loading: false,
        });
        return;
      }
    }

    if (UserService.hasRole(["SD_Medewerker", "Administrator","Retry"])) {

      let body = '{"message":"' + message + '"}';
      const jsonObject = JSON.stringify({message: message});
      let url =
        APIUrl +
        `/Berichten/Retry?InterfaceId=` +
        this.state.interfaceId +
        `&ConnectmessageId=` +
        connectmessageId;
      this.setState({
        loading: true,
        retryStatus: "",
        error: null,
      });
      this.state.axios
        .post(url,jsonObject,{
          headers: {
              'Content-Type': 'application/json',
          }})
        .then((response) => {
          this.setState({
            retrySuccess: response.data,
            retryError: null,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({
            retrySuccess: null,
            retryError: err.response.data,
            loading: false,
          });
        });
    }
  }

  MessageClicked(
    connectmessageId,
    verzender,
    ontvanger,
    verzondenTijd,
    functioneelId
  ) {
    if (UserService.hasRole(["SD_Medewerker", "Administrator", "Download"])) {
      let url =
        APIUrl +
        `/Berichten?InterfaceId=` +
        this.state.interfaceId +
        `&connectmessageId=` +
        connectmessageId;
      this.setState({
        loading: true,
        messageData: null,
        error: null,
      });
      this.state.axios
        .get(url)
        .then((response) => {
          this.setState({
            messageData: response.data,
            error: null,
            loading: false,
          });
          if (response.status == 200) {
            for (let i = 0; i < response.data.length; i++) {
              // let message = Buffer.from(
              //   response.data[i].messageText,
              //   "base64"
              // ).toString();
              let message = response.data[i].messageText;
              let messageType = response.data[i].fileExtension;
              let tag = response.data[i].tag;

              let fileName = "";
              //als de gebruiker een supplydrive medewerker is mag de tag (pickup, output of connectmessage toegevoegd worden)
              if (UserService.hasRole(["SD_Medewerker", "Administrator"])) {
                fileName += tag;
              }

              //als het functioneel id bekend is word dit de file name
              if (functioneelId != null && functioneelId != "") {
                fileName += functioneelId + "_";
              }
              //anders dit
              else {
                fileName +=
                  verzender + "_naar_" + ontvanger + " " + verzondenTijd;
              }

              if (messageType == "xml") {
                var blob = b64toBlob(message, "text/plain;charset=utf-8");
                saveAs(blob, fileName + ".xml");
              }
              if (messageType == "xlsx") {
                var blob = b64toBlob(
                  message,
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                );
                saveAs(blob, fileName + ".xlsx");
              }
              if (messageType == "pdf") {
                var blob = b64toBlob(message, "application/pdf");
                saveAs(blob, fileName + ".pdf");
              }
              if (messageType == "txt") {
                var blob = b64toBlob(message, "text/plain;charset=utf-8");
                saveAs(blob, fileName + ".txt");
              }
            }
          } else {
            this.setState({
              messageData: null,
              error: response.data,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({
            messageData: null,
            error: err.response.data,
            loading: false,
          });
        });
    }
  }

  handleFileChange = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];
    this.setState({ selectedFile: file });
    
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleFileRead;
      reader.readAsText(file);
    }
  }

  handleFileRead = (event) => {
    const content = event.target.result;
    this.setState({ fileContent: content });
  }

  render() {
    let berichtObj = this.state.berichtObj;
    console.log(berichtObj);
    console.log(this.state.interfaceId);
    let success = this.state.retrySuccess;
    return (
      <TableRowStyled
        key={this.state.key}
        className="bg-blue-200 border-black border"
      >
        <TableCell>{berichtObj.displayNaamVerzend}</TableCell>

        <TableCell>
          {berichtObj.glN_zender == null || berichtObj.glN_zender == "null"
            ? "-"
            : berichtObj.glN_zender}
        </TableCell>

        <TableCell>{berichtObj.displayNaamOntvangt}</TableCell>

        <TableCell>
          {berichtObj.glN_ontvanger == null ||
          berichtObj.glN_ontvanger == "null"
            ? "-"
            : berichtObj.glN_ontvanger}
        </TableCell>
        <TableCell>{berichtObj.functioneelID}</TableCell>
        <TableCell>{berichtObj.status}</TableCell>
        <TableCell>
          {berichtObj.verzonden_datumtijd.split("+")[0].replace("T", " ")}
        </TableCell>

        <RenderOnRole roles={["SD_Medewerker", "Administrator"]}>
          <TableCell>{berichtObj.agentname}</TableCell>
        </RenderOnRole>


        <RenderOnRole roles={["SD_Medewerker", "Administrator", "Download"]}>
          <TableCell>
          <button
              className="bg-blue-400 border-black border mr-5"
              onClick={() =>
                this.MessageClicked(
                  berichtObj.connectmessageId,
                  berichtObj.verzend_rela_naam,
                  berichtObj.ontvangt_rela_naam,
                  berichtObj.verzonden_datumtijd,
                  berichtObj.functioneelID
                )
              }
            >
              Download
            </button>
            </TableCell>
        </RenderOnRole>
        <RenderOnRole roles={["SD_Medewerker", "Administrator", "Retry"]}>
          <TableCell>
            <button
              className="bg-blue-400 border-black border mr-5"
              onClick={(e) =>
                this.ResendMessage(berichtObj.connectmessageId, e, false)
              }
            >
              Retry
            </button>
            <input
              onChange={this.handleFileChange}
              type="file"
              //ref={this.fileInputRef}
            />
            <button
              className="bg-blue-400 border-black border ml-5"
              onClick={(e) =>
                this.ResendMessage(berichtObj.connectmessageId, e, true)
              }
            >
              Retry met correctie
            </button>
            {this.state.retrySuccess ? (
              <RetryTooltip text={this.state.retrySuccess}>
                <FcCheckmark />
              </RetryTooltip>
            ) : null}

            {this.state.retryError ? (
              <RetryTooltip text={this.state.retryError}>
                <FcCancel />
              </RetryTooltip>
            ) : null}
          </TableCell>
        </RenderOnRole>
      </TableRowStyled>
    );
  }
}
export default BerichtRow;

function b64toBlob(b64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, "");
  b64Data = b64Data.replace(/\s/g, "");
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
