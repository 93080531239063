import React, {Component, useState} from "react";
import HttpService from "../services/HttpService";
import '../index.css';
import { Redirect } from "react-router";
import RenderOnRole from "../Utilities/RenderOnRole"
import Select from 'react-select';
import { APIUrl } from "../Globals";

class InterfaceToevoegenPagina extends Component{
    state={
        redirect:false,
        loading: false,
        interface:null,
        interfaceId: null,
        aanmaakdatum: null,
        artikelen:null,
        postzegels:null,
        relaties:null,
        klant:null,
        abonnement:null,
        interfaceArtikel:null,
        postzegel:null,
        startdatum:null,
        einddatum:null,
        status:"Inactief",
        statusOverschreven:false,
        mkgVerzender:null,
        mkgOntvanger:null,
        wachtwoord:null,
        auth:null,
        sftpHost:null,
        sftpPort:null,
        sftpMap:null,
        sftpUsername:null,
        sftpPassword:null,
        gebruiktNokavision:false,
        PDFMap:null,
        connectionType:null,
        GLNCode:null,
        Note:null,
        Creadentials:null,
        mail:null,
        DynamicListener:null,
        refreshToken:null,
        accessToken:null,
        error:false,
        selectedInterface:null,
        aantal:1,
        startdatum:null,
        einddatum:null,
        responseMessage:"",
        axios:null,
        update:false,
        beschikbareArtikelen:null,
        usePostzegel:true,
        factuur_referentie:null,
        cp_interface:null
    }

    componentDidMount() {
        try{
             if(this.props.location.state.selectedInterface){
                this.state.update = true;
            }
        }
        catch(ex){
            this.props.history.push("/");
        }

        this.state.klant = this.props.location.state.klantObject;
        if(this.state.klant!=null){
            this.state.abonnementen = this.state.klant.abonnementen;
        }
        const httpService = HttpService;
        httpService.configure();
        this.state.axios = httpService.getAxiosClient();

        //process.env.REACT_APP_API_URL +
        let url =  APIUrl + `/Interfaces?Postzegels=true`;
        this.setState({
            loading: true,
            postzegels: null,
            error: null
        })
        this.state.axios.get(url).then( response =>{
            this.setState({
                postzegels: response.data,
                error: null
            });
            this.setState({postzegel: this.state.postzegels[0]})
            this.ComponentDidMountCallback();
        }).catch((err)=>{
            this.setState({
                postzegels: null,
                error: typeof err.response !== "undefined" ? err.response.data.message : err.message
            })
        })

        url = APIUrl + `/Relaties`;
        this.setState({
            loading: true,
            relaties: null,
            error: null
        })
        this.state.axios.get(url).then( response =>{
            this.setState({
                relaties: response.data,
                error: null
            });
            this.setState({
                mkgVerzender: this.state.relaties.find(rela=> rela.rela_num==this.state.klant.relaNr),
                mkgOntvanger: this.state.relaties.find(rela=> rela.rela_num==this.state.klant.relaNr)
            })
            this.ComponentDidMountCallback();
        }).catch((err)=>{
            this.setState({
                relaties: null,
                error: typeof err.response !== "undefined" ? err.response.data.message : err.message
            })
        })

        url = APIUrl + `/Interfaces?Klant=0&Artikelen=true`;
        this.setState({
            loading: true,
            artikelen: null,
            error: null
        })
        this.state.axios.get(url).then( response =>{
            this.setState({
                loading: false,
                artikelen: response.data,
                error: null
            });
            this.SetBeschikbareArtikelen(this.state.abonnementen[0]);
            this.setState({
                interfaceArtikel: this.state.beschikbareArtikelen[0],
                abonnement:this.state.abonnementen[0],
                });
            this.ComponentDidMountCallback();
        }).catch((err)=>{
            this.setState({
                loading: false,
                artikelen: null,
                error: typeof err.response !== "undefined" ? err.response.data.message : err.message
            })
        })
    }

    ComponentDidMountCallback(){
        if(this.props.location.state.selectedInterface&&this.props.location.state.abonnement&&this.state.artikelen&&this.state.relaties){
            this.SetValues();
        }
    }

    SetValues(){
        let selectedInterface = this.props.location.state.selectedInterface;
        let wachtwoord = selectedInterface.wachtwoord;
        if(!selectedInterface.wachtwoord&&selectedInterface.auth){
            wachtwoord = atob(selectedInterface.auth.split("Basic ")[1]).split((selectedInterface.mkg_relaNr_ontvangt.toString()+":"))[1];
        }
        this.setState({
            interfaceId: selectedInterface.interfaceId,
            interface: selectedInterface,
            aanmaakdatum: selectedInterface.aanmaakdatum,
            abonnement: this.props.location.state.abonnement,
            interfaceArtikel: this.state.artikelen.find(art=>art.artikelNr==selectedInterface.artikelNr),
            postzegel: selectedInterface.postzegel,
            startdatum: selectedInterface.startdatum,
            einddatum: selectedInterface.einddatum,
            status: selectedInterface.status,
            statusOverschreven: selectedInterface.status_overschreven,
            mkgVerzender: this.state.relaties.find(rela=>rela.rela_num==selectedInterface.mkg_relaNr_verzend),
            mkgOntvanger: this.state.relaties.find(rela=>rela.rela_num==selectedInterface.mkg_relaNr_ontvangt),
            wachtwoord: wachtwoord,
            auth: selectedInterface.auth,
            sftpHost: selectedInterface.sftp_host,
            sftpPort: selectedInterface.sftp_poort,
            sftpMap: selectedInterface.sftp_map,
            sftpUsername: selectedInterface.sftp_gebruiker,
            sftpPassword: selectedInterface.sftp_wachtwoord,
            gebruiktNokavision: selectedInterface.gebruiktNokavision,
            PDFMap: selectedInterface.extra01,
            connectionType: selectedInterface.extra02,
            GLNCode: selectedInterface.extra03,
            Note:selectedInterface.extra04,
            Creadentials:selectedInterface.extra05,
            mail:selectedInterface.mailadres,
            DynamicListener:selectedInterface.listener,
            refreshToken: selectedInterface.refreshToken,
            accessToken: selectedInterface.accessToken,
            factuur_referentie:selectedInterface.factuur_referentie,
            cp_interface: selectedInterface.cp_interface,
            error: false,
            aantal:1,
            beschikbareArtikelen: this.state.artikelen.filter(art=>art.artikelZoekCode==selectedInterface.artikelCode)
        })
        this.SetBeschikbareArtikelen(this.state.abonnement);
    }

    ReturnMenuOption(optie){
        return <option>{optie.toString()}</option>;
    };

    SubmitInterface(){
        if(!this.state.update&&this.state.cp_interface==null){
            this.setState({responseMessage: "Er moet een connectplaza interface naam toegevoegd worden bij het aanmaken van een nieuwe interface."})
            return;
        }
        if(this.state.abonnement==null){
            this.setState({responseMessage: "Geen abonnement gekozen."})
            return;
        }
        if(this.state.interfaceArtikel==null){
            this.setState({responseMessage: "Geen interface artikel geselecteerd."})
            return;
        }
        if(this.state.aantal<1){
            this.setState({responseMessage: "Geen geldige waarde voor aantal ingevuld."})
            return;
        }
        if(this.state.auth == "Selecteer eerst een ontvanger."){
            this.setState({responseMessage: "Selecteer AUB een ontvanger en/of vernieuw de authenticatie."})
            return;
        }
        if(this.state.abonnement.einddatum!=null&&this.state.einddatum!=null&&new Date(this.state.einddatum).getTime()>new Date(this.state.abonnement.einddatum.split("T")[0]).getTime()){
            this.setState({responseMessage: "De eindatum van een interface kan niet later zijn dan de einddatum van het abonnement: "+ this.state.abonnement.einddatum.toString().split("T")[0]})
            return;
        }
        if(this.state.abonnement.startdatum!=null&&this.state.startdatum!=null&&new Date(this.state.startdatum.split("T")[0]).getTime()<new Date(this.state.abonnement.startdatum.split("T")[0]).getTime()){
            let startabo = new Date(this.state.startdatum.split("T")[0]).getTime();
            let startinter = new Date(this.state.abonnement.startdatum.split("T")[0]).getTime();
            this.setState({responseMessage: "De startdatum van een interface kan niet eerder zijn dan de startdatum van het abonnement: "+ this.state.abonnement.startdatum.toString().split("T")[0]})
            return;
        }
        
        let status = "Inactief";
        if(this.state.interface==null){
            let startdatum = this.state.startdatum;
            let einddatum = this.state.einddatum;
            let today = Date.now();
            if(startdatum!=null&&new Date(startdatum).getTime()<=today){
                status = "Actief";
            }
            // if(!startdatum&&einddatum!=null){
            //     this.setState({responseMessage: "Einddatum kan niet aangegeven zijn zonder begindatum."})
            //     return;
            // }
            if(einddatum!=null&&new Date(einddatum).getTime()<=new Date(startdatum).getTime()){
                this.setState({responseMessage: "De einddatum kan niet eerder zijn dan de startdatum."})
                return;
            }
        }
        else{
            status = this.status;
        }
        let postzegel = null;
        if(this.state.usePostzegel!=false){
            postzegel = {
                "id":this.state.postzegel.id,
                "code":this.state.postzegel.code,
                "aantalGratisBerichten":0
            };
        }
        let interfaceDTO = {
            "abonnementId": this.state.abonnement.abonnementId,
            "artikelNr": this.state.interfaceArtikel.artikelNr,
            "postzegel":postzegel,
            "startdatum": this.state.startdatum,
            "einddatum": this.state.einddatum,
            "aanmaakdatum": this.state.aanmaakdatum,
            "status": this.state.status,
            "status_overschreven": this.state.statusOverschreven,
            "mkg_relaNr_verzend": this.state.mkgVerzender.rela_num,
            "mkg_relaNr_ontvangt": this.state.mkgOntvanger.rela_num,
            "wachtwoord": this.state.wachtwoord,
            "auth": this.state.auth,
            "sftp_host": this.state.sftpHost,
            "sftp_poort": this.state.sftpPort,
            "sftp_map": this.state.sftpMap,
            "sftp_gebruiker": this.state.sftpUsername,
            "sftp_wachtwoord": this.state.sftpPassword,
            "gebruiktNokavision": this.state.gebruiktNokavision,
            "extra01": this.state.PDFMap,
            "extra02": this.state.connectionType,
            "extra03": this.state.GLNCode,
            "extra04":this.state.Note,
            "extra05":this.state.Creadentials,
            "mailadres":this.state.mail,
            "listener":this.state.DynamicListener,
            "refreshToken": this.state.refreshToken,
            "accessToken": this.state.accessToken,
            "factuur_referentie":this.state.factuur_referentie,
            "cp_interface":this.state.cp_interface,
            aantal:1
        }
        
        if(this.state.update){
            let url = APIUrl + `/Interfaces/`+this.state.interfaceId;
            this.state.axios.put(url,interfaceDTO).then(response=>{
                if(response.status==200){
                    this.state.responseMessage = "Interface succesvol geupdate.";
                    this.setState({redirect:true})
                }
            }).catch(error=>{
                this.setState({
                    responseMessage: error.toString()
                })
                
            })
        }
        else{
            let url = APIUrl + `/Interfaces`;
            this.state.axios.post(url,interfaceDTO).then(response=>{
                if(response.status==200){
                    this.state.responseMessage = "Interface succesvol toegevegd.";
                    this.setState({redirect:true})
                }
            }).catch(error=>{
                this.setState({
                    responseMessage: error.toString()
                })
            })
        }
    }

    GetAuth(){
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        var result = '';
        for ( var i = 0; i < 12; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        var base64="";
        if(this.state.mkgOntvanger){
            base64 = "Basic " + btoa((this.state.mkgOntvanger.rela_num.toString()+":"+result.toString()))
        }
        else{
            base64 = "Selecteer eerst een ontvanger.";
        }
        
        this.setState({
            wachtwoord:result,
            auth:base64
        });
    }
    
    SetPostzegelType(){
        let option = this.state.postzegels.findIndex(postzegel=> postzegel.code == this.state.postzegel.code);
        return option;
    }

    gebruiktNokavisionOnchange(evt){
        this.setState({
            gebruiktNokavision:evt.target.checked
        });
    }

    ChangeStatus(value){
        if(value!=null&&value!=this.state.status){
            this.setState({
                status:value,
                statusOverschreven:true
            })
        }
    }

    SetBeschikbareArtikelen(nieuwAbonnement){
        let artikelen = [];
        let usePostzegel = true;
        let selectedartikel = null;
        if(nieuwAbonnement){
            if(nieuwAbonnement.abonnementType.verkoopgroep_relanumartikel!=null){
                let verkoopgroep = nieuwAbonnement.abonnementType.verkoopgroep_relanumartikel;
                if(this.state.interfaceArtikel!= null&&this.state.interfaceArtikel.artikelZoekCode == verkoopgroep){
                    selectedartikel = this.state.interfaceArtikel;
                }
                let result = this.state.artikelen.filter(art=>art.artikelZoekCode==verkoopgroep);
                artikelen = artikelen.concat(result);
            }
            
                if(nieuwAbonnement.abonnementType.mkg_artikelzoekcode == 'arti8010'){
                    if(this.state.interfaceArtikel!= null&&this.state.interfaceArtikel.artikelZoekCode == '8020'){
                        selectedartikel = this.state.interfaceArtikel;
                    }
                    let result = this.state.artikelen.filter(art=>art.artikelZoekCode=='8020');
                    artikelen = artikelen.concat(result);
                }
                if(nieuwAbonnement.abonnementType.mkg_artikelzoekcode == 'arti8030'){
                    if(this.state.interfaceArtikel!= null&&this.state.interfaceArtikel.artikelZoekCode == '8030'){
                        selectedartikel = this.state.interfaceArtikel;
                    }
                    let result = this.state.artikelen.filter(art=>art.artikelZoekCode=='8030');
                    artikelen = artikelen.concat(result);
                    usePostzegel = false;
                }
            
        }
        else{
            artikelen = this.state.artikelen
            if(this.state.abonnement1!=null&&(this.state.abonnement.abonnementType.mkg_artikelzoekcode == 'arti8030'||this.state.abonnement.abonnementType.verkoopgroep_relanumartikel==1100)){
                usePostzegel = false;
            }
        }
        if(this.state.interfaceArtikel!=null){
            if((this.state.interfaceArtikel.artikelZoekCode=='8020'&&nieuwAbonnement.abonnementType.mkg_artikelzoekcode=='arti8010')||(this.state.interfaceArtikel.artikelZoekCode=='8030'&&nieuwAbonnement.abonnementType.mkg_artikelzoekcode=='arti8030')){
                this.setState({
                    interfaceArtikel: selectedartikel,
                    beschikbareArtikelen:artikelen,
                    usePostzegel: usePostzegel,
                    responseMessage:""
                })
            }
            else{
                //artikelen.push(this.state.interfaceArtikel);

                this.setState({
                    interfaceArtikel: selectedartikel,
                    beschikbareArtikelen:artikelen,
                    usePostzegel: usePostzegel,
                    //responseMessage:"Het huidige interface artikel is niet beschikbaar met het gekozen abonnement."
                })
            }
        }
        else{
            this.setState({
                beschikbareArtikelen:artikelen,
                interfaceArtikel:artikelen[0],
                usePostzegel: usePostzegel
            })
        }
    }

    SetInterfaceArtikel(evt){
        this.setState({interfaceArtikel:this.state.beschikbareArtikelen[evt.value]});
    }

    render(){ 
        if(this.state.redirect){
            return(<Redirect to={"/klant/"+this.state.klant.klantId}/>)
        }
        if(this.state.klant!=null&&this.state.artikelen!=null&&this.state.postzegels!=null&&this.state.abonnementen!=null&&this.state.relaties!=null&&this.state.beschikbareArtikelen!=null&&this.state.loading==false){
            let interfaceArtikelLabels = this.state.beschikbareArtikelen.map((opt, i) => ({ label: opt.artikelNr.toString(), value: i }));
            let relatieLables = this.state.relaties.map((opt,i)=>({ label: (opt.rela_num.toString()+" "+opt.rela_naam.toString()), value: i }))
            return(<div>
                <div class="grid grid-cols-8 m-4">
                        {this.state.interface
                            ? (<div class="m-2 col-span-1">ID: </div>)
                            : null
                        }
                        {this.state.interface
                            ? (<input class="m-2 col-span-7 border border-black bg-gray-200 w-full" type="text" value={this.state.interfaceId} readOnly={true}/>)
                            : null
                        }
                        
                        {this.state.interface
                            ? (<div class="m-2 col-span-1">Aanmaakdatum: </div>)
                            : null
                        }
                        {this.state.interface
                            ? (<input class="m-2 col-span-7 border border-black bg-gray-200 w-full" type="text" value={this.state.aanmaakdatum.toString().split("T")[0]} readOnly={true}/>)
                            : null
                        }

                        <div class="m-2 col-span-1">Valt onder abonnement: </div>
                        <select name="selectAbonnement" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.setState({abonnement: this.state.abonnementen[evt.target.value]});this.SetBeschikbareArtikelen(this.state.abonnementen[evt.target.value])}} value={this.state.abonnement? (this.state.abonnementen.indexOf(this.state.abonnement)): 0}>
                            {this.state.abonnementen.map((abo, i) => (<option value={i}>{abo.abonnementType.naam.toString()}</option>))}
                        </select>

                        <div class="col-span-8 grid grid-cols-8 ">
                            <div class="mt-4 ml-2 col-span-1">Artikelnr: </div>
                            <div class="m-2 col-span-7">
                                <Select name="selectArtikel" class="border border-black w-full" onChange={evt=>{this.SetInterfaceArtikel(evt)}} 
                                value={this.state.interfaceArtikel? (interfaceArtikelLabels.filter(arti => arti.label == this.state.interfaceArtikel.artikelNr)):interfaceArtikelLabels[0]} 
                                options={interfaceArtikelLabels}/>
                                {/* {this.state.beschikbareArtikelen.map((art,i) => (<option value={i}>{art.artikelNr.toString()}</option>))}
                            </select> */}
                            </div>
                        </div>

                        <div class="m-2 col-span-1">ConnectPlaza interface: </div>
                        <input name="email" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({cp_interface:evt.target.value})}} value={this.state.cp_interface}></input>

                        {this.state.usePostzegel
                            ?(<div class="col-span-8 grid grid-cols-8 ">
                                <div class="m-2 col-span-1">Postzegels: </div>
                                    <select name="selectPostzegel" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.setState({postzegel:this.state.postzegels[evt.target.value]})}} value={this.state.postzegel? this.SetPostzegelType():0}>
                                        {this.state.postzegels.map((post, i) => (<option value={i}>{post.code.toString()}</option>))}
                                    </select>
                                </div>)
                            :null}
                        

                        <RenderOnRole roles={["Administrator"]}>
                            <div class="m-2 col-span-1">Startdatum: </div>
                            <input name="selectStartdate" type="date" class="m-2 col-span-7  border border-black w-full" onChange={evt=>{this.state.startdatum=evt.target.value}} defaultValue={this.state.startdatum? this.state.startdatum.toString().split("T")[0]:null}/>
                            
                            <div class="m-2 col-span-1">Status: </div>
                            <select name="selectStatus" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.ChangeStatus(evt.target.value)}} value={this.state.status? this.state.status:null}>
                                {!this.state.status? <option value={null}></option>:null}
                                <option value={"Actief"}>Actief</option>
                                <option value={"Inactief"}>Inactief</option>
                            </select>
                        </RenderOnRole>

                        <div class="m-2 col-span-1">Einddatum: </div>
                        <input name="selectEnddate" type="date" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.state.einddatum=evt.target.value}} defaultValue={this.state.einddatum? this.state.einddatum.toString().split("T")[0]:null}/>

                        <div class="m-2 col-span-1">MKG relanr verzender: </div>
                        {/* <select name="mkgVerzender" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.setState({mkgVerzender:this.state.relaties[evt.target.value]})}} 
                        value={this.state.mkgVerzender? (this.state.relaties.indexOf(this.state.mkgVerzender)): 0}>
                                    {this.state.relaties.map((verz,i) => (<option value={i}>{(verz.rela_num.toString()+" "+verz.rela_naam.toString())}</option>))}
                        </select> */}
                        <div class="m-2 col-span-7">
                            <Select name="mkgVerzender" class="border border-black w-full" onChange={evt=>{this.setState({mkgVerzender:this.state.relaties[evt.value]})}}
                                    value={this.state.mkgVerzender? (relatieLables.filter(rela => rela.label == (this.state.mkgVerzender.rela_num.toString()+" "+this.state.mkgVerzender.rela_naam.toString()))):relatieLables[0]} 
                                    options={relatieLables}/>
                        </div>
                        <div class="m-2 col-span-1">MKG verzender uniek id: </div>
                        {this.state.mkgVerzender?<input class="m-2 col-span-7 bg-gray-200 border border-black" type="text" readOnly={true} value={this.state.mkgVerzender.uniekid}/>:null}

                        {/* <div class="m-4">MKG rela verzender uniek ID: </div> */}
                        <div class="m-2 col-span-1">MKG relanr ontvangt:* </div>
                        {/* <select name="mkgOntvanger" class="m-2 col-span-7 border border-black w-full" onChange={evt=>{this.setState({mkgOntvanger:this.state.relaties[evt.target.value]})}} value={this.state.mkgOntvanger? (this.state.relaties.indexOf(this.state.mkgOntvanger)): 0}>
                            {this.state.relaties.map((ont,i) => (<option value={i}>{(ont.rela_num.toString()+" "+ont.rela_naam.toString())}</option>))}
                        </select> */}
                        <div class="m-2 col-span-7">
                            <Select name="mkgOntvanger" class="border border-black w-full" onChange={evt=>{this.setState({mkgOntvanger:this.state.relaties[evt.value]})}}
                                    value={this.state.mkgOntvanger? (relatieLables.filter(rela => rela.label == (this.state.mkgOntvanger.rela_num.toString()+" "+this.state.mkgOntvanger.rela_naam.toString()))):relatieLables[0]} 
                                    options={relatieLables}/>
                        </div>
                        <div class="m-2 col-span-1">MKG ontvangt uniek id: </div>
                        {this.state.mkgOntvanger?<input class="m-2 col-span-7 bg-gray-200 border border-black" type="text" readOnly={true} value={this.state.mkgOntvanger.uniekid}/>:null}
                        

                        {/* <div class="m-4">MKG rela ontvangt uniek ID: </div> */}
                        
                        
                            <div class="m-2 col-span-1">Authenticatie: </div>
                            <button name="btnSetAuth" class="m-2 col-span-1 bg-blue-200 rounded-lg" onClick={()=>this.GetAuth()}>Reset</button>
                            <input name="wachtwoord" class="m-2 col-span-3 border border-black " type="text" onChange={evt=>{this.state.wachtwoord=evt.target.value}} defaultValue={this.state.wachtwoord}></input>
                            <input name="auth" class="m-2 col-span-3 border border-black bg-gray-200 " type="text" value={this.state.auth} readOnly={true}></input>
                        

                        <div class="m-2 col-span-1">Refresh token: </div>
                        <input name="refreshtoken" class="m-2 col-span-7 border border-black w-full" type="text" value={this.state.refreshToken} onChange={evt=>{this.setState({refreshToken:evt.target.value})}}></input>

                        <div class="m-2 col-span-1">SFTP </div>
                        <div class="col-span-7 grid grid-cols-6 justify-items-start">
                            <div class="m-2 col-span-1">Host: </div>
                            <input name="sftphost" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.setState({sftpHost:evt.target.value})}} value={this.state.sftpHost}></input>

                            <div class="m-2 col-span-1 justify-items-center">Poort: </div>
                            <input name="sftppoort" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.setState({sftpPort:evt.target.value})}} value={this.state.sftpPort}></input>

                            <div class="m-2 col-span-1">Map: </div>
                            <input name="sftpmap" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.setState({sftpMap:evt.target.value})}} value={this.state.sftpMap}></input>

                            <div class="m-2 col-span-1">Gebruikersnaam: </div>
                            <input name="sftpuser" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.setState({sftpUsername:evt.target.value})}} value={this.state.sftpUsername}></input>

                            <div class="m-2 col-span-1">Wachtwoord: </div>
                            <input name="sftppassword" class="m-2 border border-black col-span-5 w-full" type="text" onChange={evt=>{this.setState({sftpPassword:evt.target.value})}} value={this.state.sftpPassword}></input>
                        </div>



                        <div class="m-2 col-span-1">Gebruikt Nokavision:</div>
                        <input name="cbGebruiktNokavision" type="checkbox" class="m-2 col-span-7 border border-black w-full" checked={this.state.gebruiktNokavision} onChange={evt=>{this.setState({gebruiktNokavision:evt.target.checked});}}/>
                        
                        <div class="m-2 col-span-1">PDF map: </div>
                        <input name="pdfMap" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({PDFMap:evt.target.value})}} value={this.state.PDFMap}></input>
                        
                        <div class="m-2 col-span-1">Connectie type: </div>
                        <input name="connectieType" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({connectionType:evt.target.value})}} value={this.state.connectionType}></input>

                        <div class="m-2 col-span-1">GLN code: </div>
                        <input name="glnCode" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({GLNCode:evt.target.value})}} value={this.state.GLNCode}></input>

                        <div class="m-2 col-span-1">Opmerkingen: </div>
                        <input name="opmerkingen" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({Note:evt.target.value})}} value={this.state.Note}></input>

                        <div class="m-2 col-span-1">Credentials: </div>
                        <input name="credentials" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({Creadentials:evt.target.value})}} value={this.state.Creadentials}></input>

                        <div class="m-2 col-span-1">E-mail: </div>
                        <input name="email" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({mail:evt.target.value})}} value={this.state.mail}></input>

                        <div class="m-2 col-span-1">Dynamische listener: </div>
                        <input name="dynamische listener" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({DynamicListener:evt.target.value})}} value={this.state.DynamicListener}></input>

                        <div class="m-2 col-span-1">Factuur referentie: </div>
                        <input name="factuur_referentie" class="m-2 col-span-7 border border-black w-full" type="text" onChange={evt=>{this.setState({factuur_referentie:evt.target.value})}} value={this.state.factuur_referentie}></input>

                        <div class="col-span-1"/>
                        <button name="btnSubmit" class="col-span-1 h-10 bg-blue-200 m-2 mt-5 border border-black rounded-lg" onClick={()=>{this.SubmitInterface()}}>
                            {this.state.interface? "Interface updaten":"Interface toevoegen"}
                        </button>
                        <div class="col-span-6   text-red-500" name="responseMessage">{this.state.responseMessage}</div>
                    
                    
                </div>
            </div>)
        }
        if(this.state.error){
            return(<div>Er is iets mis gegaan: {this.state.error}</div>)
        }
        if(this.state.loading){
            return(<div>Loading...</div>)
        }
        return(<div>Geen klant gekozen.</div>)
    }
}
export default InterfaceToevoegenPagina;